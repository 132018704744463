import React, { useState, useEffect, useRef } from "react";
import SideNavBar from "./SideNavBar";
import LoadingIndicator from "./LoadingIndicator";
import { MdReplay } from "react-icons/md";
import ConfirmationModal from "./ConfirmationModal";
import { BsCheckLg } from "react-icons/bs";
import { jsPDF } from "jspdf";
import "./Chat.css";
import { Toolbar } from "@mui/material";
import { AlluraFont } from "./allura";

function DocumentGeneration() {
  const MAX_MESSAGES = 30;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // User info states
  const [userFirstName, setUserFirstName] = useState("");
  const [userLastName, setUserLastName] = useState("");
  const [userPhone, setUserPhone] = useState("");
  const [userAddress, setUserAddress] = useState("");
  const [userCity, setUserCity] = useState("");
  const [userState, setUserState] = useState("");
  const [userZip, setUserZip] = useState("");
  const [userCompanyName, setUserCompanyName] = useState("");
  const [userCustomsEmail, setUserCustomsEmail] = useState("");
  const [userCompanyLogo, setUserCompanyLogo] = useState("");
  const [userCompanyWebsite, setUserCompanyWebsite] = useState("");

  const [showGeneratePdfButton, setShowGeneratePdfButton] = useState(false);

  const [chatHistory, setChatHistory] = useState(() => {
    const storedData = localStorage.getItem("document_generation_chat");
    return storedData ? JSON.parse(storedData) : [];
  });

  const [input, setInput] = useState("");
  const chatBodyRef = useRef(null);
  const inputRef = useRef(null);

  useEffect(() => {
    let sessionId = sessionStorage.getItem("session_id");
    if (!sessionId) {
      sessionId = generateSessionId();
      sessionStorage.setItem("session_id", sessionId);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem(
      "document_generation_chat",
      JSON.stringify(chatHistory)
    );
  }, [chatHistory]);

  useEffect(() => {
    const storedCompanyName = sessionStorage.getItem("storageCompanyName");
    const storedCustomsEmail = sessionStorage.getItem("storageCustomsEmail");
    const storedFirstName = sessionStorage.getItem("storageFirstName");
    const storedLastName = sessionStorage.getItem("storageLastName");
    const storedPhone = sessionStorage.getItem("storagePhone");
    const storedAddress = sessionStorage.getItem("storageAddress");
    const storedCity = sessionStorage.getItem("storageCity");
    const storedState = sessionStorage.getItem("storageState");
    const storedZip = sessionStorage.getItem("storageZip");
    const storedCompanyLogo = sessionStorage.getItem("storageCompanyLogo");
    const storedCompanyWebsite = sessionStorage.getItem(
      "storageCompanyWebsite"
    );

    if (storedCompanyName) setUserCompanyName(storedCompanyName);
    if (storedCustomsEmail) setUserCustomsEmail(storedCustomsEmail);
    if (storedFirstName) setUserFirstName(storedFirstName);
    if (storedLastName) setUserLastName(storedLastName);
    if (storedPhone) setUserPhone(storedPhone);
    if (storedAddress) setUserAddress(storedAddress);
    if (storedCity) setUserCity(storedCity);
    if (storedState) setUserState(storedState);
    if (storedZip) setUserZip(storedZip);
    if (storedCompanyLogo) setUserCompanyLogo(storedCompanyLogo);
    if (storedCompanyWebsite) setUserCompanyWebsite(storedCompanyWebsite);
  }, []);

  useEffect(() => {
    if (chatBodyRef.current) {
      chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight;
    }
  }, [chatHistory, isLoading]);

  const openModal = () => {
    setIsModalOpen(true);
    setShowGeneratePdfButton(false);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const confirmNewChat = async () => {
    await handleRequestCompleted(false);
    setChatHistory([]);
    addSystemMessage("How can I assist you with Paps and Pars Authorizations?");
    closeModal();
  };

  const addSystemMessage = (text) => {
    const newMessage = {
      id: generateUniqueId(),
      text,
      isUser: false,
    };
    setChatHistory((prevHistory) =>
      [...prevHistory, newMessage].slice(-MAX_MESSAGES)
    );
  };

  const handleRequestCompleted = async (displayMessage = true) => {
    const textToSend = "This part is completed";
    setIsLoading(true);

    if (displayMessage) {
      const userMessage = {
        id: generateUniqueId(),
        text: textToSend,
        isUser: true,
      };
      setChatHistory((prevHistory) =>
        [...prevHistory, userMessage].slice(-MAX_MESSAGES)
      );
    }

    try {
      const response = await callAzureFunction(textToSend);
      const answer = response.message;

      const aiMessage = {
        id: generateUniqueId(),
        text: answer,
        isUser: false,
      };
      setChatHistory((prevHistory) =>
        [...prevHistory, aiMessage].slice(-MAX_MESSAGES)
      );
    } catch (err) {
      console.error("Error fetching response from the API:", err);
      const errorMessage = {
        id: generateUniqueId(),
        text: "Error fetching response from the system.",
        isUser: false,
      };
      setChatHistory((prevHistory) =>
        [...prevHistory, errorMessage].slice(-MAX_MESSAGES)
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleSend = async () => {
    const textToSend = input.trim();
    if (textToSend === "") return;

    const userMessage = {
      id: generateUniqueId(),
      text: textToSend,
      isUser: true,
    };
    setChatHistory((prevHistory) =>
      [...prevHistory, userMessage].slice(-MAX_MESSAGES)
    );
    setInput("");
    setIsLoading(true);

    try {
      const response = await callAzureFunction(textToSend);
      const data = response;

      const aiMessage = {
        id: generateUniqueId(),
        text: data.message,
        isUser: false,
      };
      setChatHistory((prevHistory) =>
        [...prevHistory, aiMessage].slice(-MAX_MESSAGES)
      );

      // Show the Generate PDF button after AI responds
      setShowGeneratePdfButton(true);
    } catch (err) {
      console.error("Error fetching response from the API:", err);
      const errorMessage = {
        id: generateUniqueId(),
        text: "Error fetching response from the system.",
        isUser: false,
      };
      setChatHistory((prevHistory) =>
        [...prevHistory, errorMessage].slice(-MAX_MESSAGES)
      );
    } finally {
      setIsLoading(false);
    }
  };

  const callAzureFunction = async (inputText) => {
    const apiUrl = "/api/document-generation";

    const response = await fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        question: inputText,
      }),
    });

    if (!response.ok) {
      throw new Error("API request failed");
    }

    return await response.json();
  };

  // A small helper to draw a footer on each page
  const drawFooter = (doc) => {
    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();

    doc.setLineWidth(0.5);
    // Simple horizontal line near the bottom
    doc.line(50, pageHeight - 50, pageWidth - 50, pageHeight - 50);

    doc.setFontSize(9);
    doc.setFont("Times", "normal");
    doc.setTextColor(100);

    // Place text slightly above bottom margin
    doc.text(`Generated by LoadMinds ©`, 50, pageHeight - 35);
  };

  const handleGeneratePdf = async () => {
    const messages = chatHistory;
    const lastSystemMessage = [...messages]
      .reverse()
      .find((msg) => !msg.isUser && !msg.isMap);

    if (!lastSystemMessage) {
      alert("No system message found to generate PDF.");
      return;
    }

    // Create a new jsPDF instance
    const doc = new jsPDF({
      orientation: "portrait",
      unit: "pt",
      format: "a4",
    });

    // Basic page variables
    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();
    const marginLeft = 50;
    const marginRight = 50;
    const marginTop = 50;
    const marginBottom = 50;
    const usableWidth = pageWidth - marginLeft - marginRight;

    let yPosition = marginTop;

    // Attempt to fetch and convert logo image to base64
    let logoBase64Data = null;
    if (userCompanyLogo) {
      try {
        const response = await fetch(userCompanyLogo, { mode: "cors" });
        const blob = await response.blob();
        const reader = new FileReader();

        const loadPromise = new Promise((resolve, reject) => {
          reader.onloadend = () => resolve(reader.result);
          reader.onerror = reject;
        });

        reader.readAsDataURL(blob);
        logoBase64Data = await loadPromise;
      } catch (error) {
        console.error("Error loading company logo:", error);
      }
    }

    // -----------------------------
    // 1) HEADER: Company Info & Logo
    // -----------------------------
    // Define the desired width/height for the bigger logo
    const logoWidth = 180; // Increase or decrease as desired
    const logoHeight = 80;

    if (logoBase64Data) {
      // Place the logo at the top-right corner:
      // x = pageWidth - marginRight - logoWidth
      // y = yPosition
      doc.addImage(
        logoBase64Data,
        "PNG",
        pageWidth - marginRight - logoWidth,
        yPosition,
        logoWidth,
        logoHeight
      );
    }

    // Company name on the left
    doc.setFont("Times", "bold");
    doc.setFontSize(16);
    doc.text(`${userCompanyName || ""}`, marginLeft, yPosition + 20);

    doc.setFontSize(12);
    doc.setFont("Times", "normal");

    // Show address lines under the company name
    let headerYPos = yPosition + 40; // Give extra space below the company name

    if (userAddress) {
      doc.text(`${userAddress}`, marginLeft, headerYPos);
      headerYPos += 12;
    }
    if (userCity || userState || userZip) {
      doc.text(`${userCity}, ${userState} ${userZip}`, marginLeft, headerYPos);
      headerYPos += 25;
    }
    if (userPhone) {
      doc.setFont("Times", "bold");
      doc.setFontSize(12);
      doc.text("Phone:", marginLeft, headerYPos);

      doc.setFont("Times", "normal");
      doc.text(userPhone, marginLeft + 37, headerYPos);

      headerYPos += 12;
    }

    if (userCustomsEmail) {
      doc.setFont("Times", "bold");
      doc.setFontSize(12);
      doc.text("Email:", marginLeft, headerYPos);

      doc.setFont("Times", "italic");
      doc.text(userCustomsEmail, marginLeft + 37, headerYPos);

      headerYPos += 12;
    }
    if (userCompanyWebsite) {
      doc.setFont("Times", "bold");
      doc.setFontSize(12);
      doc.text("Website: ", marginLeft, headerYPos);

      doc.setFont("Times", "italic");
      doc.text(userCompanyWebsite, marginLeft + 48, headerYPos);

      headerYPos += 12;
    }

    // Make sure we skip below the logo if it's taller
    // This ensures we don't overlap the logo on the right
    const logoBottom = yPosition + logoHeight;
    // Whichever is further down, use that as new Y
    headerYPos = Math.max(headerYPos, logoBottom);

    // Add a line under the header
    doc.setLineWidth(1);
    doc.line(
      marginLeft,
      headerYPos + 10,
      pageWidth - marginRight,
      headerYPos + 10
    );

    // Adjust yPosition for the body content to start below the header
    yPosition = headerYPos + 30;

    // -------------
    // 2) BODY Content
    // -------------
    const lines = lastSystemMessage.text.split("\n");
    doc.setFont("Times", "normal");
    doc.setFontSize(12);

    lines.forEach((rawLine) => {
      const line = rawLine.trim();

      if (line === "") {
        // Add extra spacing for blank lines
        yPosition += 10;
      } else {
        // Split the line if it's too long
        const textLines = doc.splitTextToSize(line, usableWidth);
        doc.text(textLines, marginLeft, yPosition);
        yPosition += textLines.length * 14; // Adjust line-height as needed
      }

      // If we're close to the bottom of the page, add a new page
      if (yPosition > pageHeight - marginBottom) {
        drawFooter(doc);
        doc.addPage();
        yPosition = marginTop;
      }
    });

    // ----------------
    // 3) SIGNATURE BLOCK
    // ----------------
    // -- Signature Block --
    yPosition += 40; // some extra space before the signature block

    doc.addFileToVFS("Allura-Regular.ttf", AlluraFont);
    doc.addFont("Allura-Regular.ttf", "Allura", "normal");

    // Draw a signature line (optional)
    doc.setFont("Allura");
    doc.setFontSize(22); // Adjust font size as needed
    doc.text(`${userFirstName} ${userLastName}`, marginLeft, yPosition);

    yPosition += 10;
    doc.text("___________", marginLeft, yPosition);

    yPosition += 20;
    // Printed Name (First name, Last name)
    doc.setFont("Times", "normal"); // Ensure a professional font
    doc.setFontSize(14);
    doc.text(`${userFirstName} ${userLastName}`, marginLeft, yPosition);

    yPosition += 18; // Adjust spacing between printed name and title

    // Title
    doc.setFontSize(12);
    doc.text("Dispatcher", marginLeft, yPosition);

    yPosition += 16; // Adjust spacing between title and company

    // Company Name
    doc.setFontSize(12);
    doc.text(`${userCompanyName}`, marginLeft, yPosition);

    // Draw the footer on the final page
    drawFooter(doc);

    // Finally, save the PDF
    doc.save("authorizationLetter.pdf");
  };

  const adjustInputHeight = () => {
    if (inputRef.current) {
      inputRef.current.style.height = "auto";
      inputRef.current.style.height = `${Math.min(
        inputRef.current.scrollHeight,
        150
      )}px`;
    }
  };

  useEffect(() => {
    adjustInputHeight();
  }, [input]);

  const generateUniqueId = () => {
    return `${Date.now()}-${Math.random().toString(36).substr(2, 9)}`;
  };

  const generateSessionId = () => {
    return crypto.randomUUID();
  };

  return (
    <div className="chat-container">
      <SideNavBar handleNewChat={openModal} />
      <ConfirmationModal
        open={isModalOpen}
        handleClose={closeModal}
        handleConfirm={confirmNewChat}
        option="Document Generation"
      />
      <div className="chat-window">
        <Toolbar sx={{ minHeight: "84px" }} />
        <div className="chat-body" ref={chatBodyRef}>
          {chatHistory.map((message) => (
            <div
              key={message.id}
              className={`chat-message ${
                message.isUser ? "user-message" : "system-message"
              }`}
            >
              {message.text}
            </div>
          ))}
          {isLoading && <LoadingIndicator />}
          {showGeneratePdfButton && (
            <div className="chat-message request-completed-option">
              <span style={{ marginRight: "10px" }}>Generate PDF</span>
              <button className="complete-button" onClick={handleGeneratePdf}>
                <BsCheckLg />
              </button>
            </div>
          )}
        </div>
        <div className="chat-footer">
          <button
            className="file-upload-btn"
            title="New Conversation"
            style={{ border: "none", marginRight: "10px" }}
            onClick={openModal}
          >
            <MdReplay />
          </button>
          <textarea
            ref={inputRef}
            value={input}
            onChange={(e) => {
              setInput(e.target.value);
              adjustInputHeight();
            }}
            onKeyDown={(e) => e.key === "Enter" && !e.shiftKey && handleSend()}
            placeholder="Type a message..."
            className="input-field"
          />
          <button
            className="send-btn"
            title="Send Message"
            onClick={handleSend}
            aria-label="Send Message"
            disabled={isLoading}
          >
            ➤
          </button>
        </div>
      </div>
    </div>
  );
}

export default DocumentGeneration;
