import React, { useState, useEffect } from "react";
import {
  Card,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Toolbar,
  Typography,
  Pagination,
  Paper,
  Box,
  ToggleButton,
  ToggleButtonGroup,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import Spinner from "./Spinner";
import SideNavBar from "./SideNavBar";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import "./Invoices.css";

function Invoices() {
  const [loads, setLoads] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [invoiceFilter, setInvoiceFilter] = useState("paid"); // "paid" or "unpaid"
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  // States related to Payment Dialog
  const [isPaymentDialogOpen, setIsPaymentDialogOpen] = useState(false);
  const [selectedLoad, setSelectedLoad] = useState(null);
  const [newPaymentDate, setNewPaymentDate] = useState("");
  const [newPaymentPrice, setNewPaymentPrice] = useState("");

  useEffect(() => {
    async function fetchInvoiceLoads() {
      const storedUserId = sessionStorage.getItem("storageUserId");
      setIsLoading(true);
      try {
        const response = await fetch(
          `/api/fetch-invoices?userId=${encodeURIComponent(
            storedUserId
          )}&type=${encodeURIComponent(invoiceFilter)}`,
          {
            method: "GET",
            headers: { "Content-Type": "application/json" },
          }
        );
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        setLoads(data.result || []);
      } catch (error) {
        console.error("Failed to fetch invoice loads:", error);
      } finally {
        setIsLoading(false);
      }
    }

    fetchInvoiceLoads();
  }, [invoiceFilter]);

  const handleInvoiceFilter = (event, newFilter) => {
    if (newFilter !== null) {
      setInvoiceFilter(newFilter);
      setCurrentPage(1);
    }
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);
  };

  // Filter for searching
  const filteredItems = loads.filter((item) => {
    const searchLower = searchTerm.toLowerCase();
    return (
      item.CustomerOrderNumber?.toString()
        .toLowerCase()
        .includes(searchLower) ||
      item.OrderNumber?.toLowerCase().includes(searchLower) ||
      item.Company?.toLowerCase().includes(searchLower) ||
      item.InvoiceDate?.toLowerCase().includes(searchLower) ||
      (item.PaymentDate || "")?.toLowerCase().includes(searchLower)
    );
  });

  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredItems.slice(indexOfFirstItem, indexOfLastItem);

  // Dark theme (similar to OrderHistory.js)
  const darkTheme = createTheme({
    palette: {
      mode: "dark",
      background: {
        default: "#000000",
        paper: "#000000",
      },
      text: {
        primary: "#ffffff",
        secondary: "#aaaaaa",
      },
      primary: {
        main: "#3c94fc",
      },
    },
  });

  // Handle Payment Received
  const handlePaymentReceivedClick = (load) => {
    setSelectedLoad(load);
    setNewPaymentDate("");
    // Initialize price field with the current price from the load
    setNewPaymentPrice(load.Price || "");
    setIsPaymentDialogOpen(true);
  };

  const handleConfirmPayment = async () => {
    if (!selectedLoad) return;
    try {
      // Example endpoint for updating payment date & price
      const response = await fetch("/api/update-payment-date", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          customerOrderNumber: selectedLoad.CustomerOrderNumber,
          paymentDate: newPaymentDate,
          price: newPaymentPrice,
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      alert("Payment date and price updated successfully!");

      // Locally update PaymentDate and Price in the state
      setLoads((prev) =>
        prev.map((item) =>
          item.CustomerOrderNumber === selectedLoad.CustomerOrderNumber
            ? { ...item, PaymentDate: newPaymentDate, Price: newPaymentPrice }
            : item
        )
      );
    } catch (error) {
      console.error("Failed to update payment date or price:", error);
      alert(`Error updating payment info: ${error.message}`);
    } finally {
      setIsPaymentDialogOpen(false);
      setSelectedLoad(null);
    }
  };

  const handleCancelPayment = () => {
    setIsPaymentDialogOpen(false);
    setSelectedLoad(null);
  };

  return (
    <ThemeProvider theme={darkTheme}>
      <div className="invoices-history-container">
        <SideNavBar />
        <div className="invoices-history-window">
          <Toolbar style={{ minHeight: "83px" }} />
          <div className="invoices-history-body">
            <Card sx={{ maxWidth: "100%", overflowX: "auto", m: 2 }}>
              <Typography
                variant="h4"
                component="div"
                sx={{
                  m: 2,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                Invoices
              </Typography>

              {/* Toggle Button Group for Paid/Unpaid */}
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                width="100%"
                sx={{ mb: 2 }}
              >
                <ToggleButtonGroup
                  value={invoiceFilter}
                  exclusive
                  onChange={handleInvoiceFilter}
                  aria-label="invoice filter"
                >
                  <ToggleButton value="paid" aria-label="Paid">
                    Paid
                  </ToggleButton>
                  <ToggleButton value="unpaid" aria-label="Unpaid">
                    Unpaid
                  </ToggleButton>
                </ToggleButtonGroup>
              </Box>

              {/* Search Field */}
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                width="100%"
              >
                <TextField
                  variant="outlined"
                  value={searchTerm}
                  onChange={handleSearchChange}
                  placeholder="Search by any column"
                  margin="normal"
                  sx={{
                    m: 2,
                    width: "50%",
                    input: { color: "#fff" },
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "#555",
                      },
                      "&:hover fieldset": {
                        borderColor: "#777",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#3c94fc",
                      },
                    },
                  }}
                  InputLabelProps={{
                    style: { color: "#fff" },
                  }}
                />
              </Box>

              {isLoading ? (
                <Box
                  sx={{
                    m: 2,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  <Spinner sx={{ color: "#3c94fc" }} />
                </Box>
              ) : (
                <TableContainer component={Paper}>
                  <Table stickyHeader aria-label="invoices table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Customer Order#</TableCell>
                        <TableCell>Invoice #</TableCell>
                        <TableCell>Company</TableCell>
                        <TableCell>Invoice Date</TableCell>
                        <TableCell>Price</TableCell>
                        {/* Only show Payment Date column if filter is "paid" */}
                        {invoiceFilter === "paid" && (
                          <TableCell>Payment Date</TableCell>
                        )}
                        {/* An action column for 'Payment Received' if filter is "unpaid" */}
                        {invoiceFilter === "unpaid" && (
                          <TableCell>Action</TableCell>
                        )}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {currentItems.length > 0 ? (
                        currentItems.map((item, index) => (
                          <TableRow key={index}>
                            <TableCell>{item.CustomerOrderNumber}</TableCell>
                            {/* For Invoices, Invoice# is the same as Order# */}
                            <TableCell>{item.OrderNumber || "N/A"}</TableCell>
                            <TableCell>{item.Company}</TableCell>
                            <TableCell>
                              {item.InvoiceDate
                                ? new Date(item.InvoiceDate).toLocaleDateString()
                                : "N/A"}
                            </TableCell>
                            <TableCell>{item.Price}</TableCell>
                            {/* Show Payment Date if "paid" */}
                            {invoiceFilter === "paid" && (
                              <TableCell>
                                {item.PaymentDate
                                  ? new Date(item.PaymentDate).toLocaleDateString()
                                  : ""}
                              </TableCell>
                            )}
                            {/* Show Payment Received button if "unpaid" */}
                            {invoiceFilter === "unpaid" && (
                              <TableCell>
                                <Button
                                  variant="contained"
                                  color="primary"
                                  onClick={() => handlePaymentReceivedClick(item)}
                                >
                                  Mark Paid
                                </Button>
                              </TableCell>
                            )}
                          </TableRow>
                        ))
                      ) : (
                        <TableRow>
                          <TableCell colSpan={6} align="center">
                            No invoices found
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                  <Pagination
                    count={Math.ceil(filteredItems.length / itemsPerPage)}
                    page={currentPage}
                    onChange={(event, value) => setCurrentPage(value)}
                    color="primary"
                    sx={{
                      py: 2,
                      display: "flex",
                      justifyContent: "center",
                    }}
                    showFirstButton
                    showLastButton
                  />
                </TableContainer>
              )}

              {/* Payment Confirmation Dialog */}
              <Dialog
                open={isPaymentDialogOpen}
                onClose={handleCancelPayment}
                aria-labelledby="payment-dialog-title"
              >
                <DialogTitle id="payment-dialog-title">Mark as Paid</DialogTitle>
                <DialogContent>
                  <Typography>
                    You are about to mark Customer Order#
                    <strong> {selectedLoad?.CustomerOrderNumber}</strong> as
                    paid.
                  </Typography>
                  <Box sx={{ mt: 2 }}>
                    <TextField
                      label="Payment Date"
                      type="date"
                      value={newPaymentDate}
                      onChange={(e) => setNewPaymentDate(e.target.value)}
                      sx={{ width: "100%", mb: 2 }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    <TextField
                      label="Price"
                      type="number"
                      value={newPaymentPrice}
                      onChange={(e) => setNewPaymentPrice(e.target.value)}
                      sx={{ width: "100%" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Box>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleCancelPayment} color="secondary">
                    Cancel
                  </Button>
                  <Button
                    onClick={handleConfirmPayment}
                    color="primary"
                    variant="contained"
                  >
                    Confirm
                  </Button>
                </DialogActions>
              </Dialog>
            </Card>
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
}

export default Invoices;
