import React, { useState, useEffect, useCallback } from "react";
import { PDFDocument, StandardFonts } from "pdf-lib";
import bwipjs from "bwip-js";
import {
  TextField,
  Checkbox,
  FormControlLabel,
  Button,
  Toolbar,
} from "@mui/material";
import Spinner from "./Spinner";
import SideNavBar from "./SideNavBar";
import "./Edit.css";

function Edit() {
  const [searchTerm, setSearchTerm] = useState("");
  const [editData, setEditData] = useState(null);
  const [originalData, setOriginalData] = useState(null);
  const [inRush, setInRush] = useState(false);
  const [emailPrefix, setEmailPrefix] = useState("");
  const [userId, setUserId] = useState(null);
  const [userCompanyName, setUserCompanyName] = useState("");
  const [userCustomsEmail, setUserCustomsEmail] = useState("");
  const [userFirstName, setUserFirstName] = useState("");
  const [userLastName, setUserLastName] = useState("");
  const [userPhone, setUserPhone] = useState("");
  const [userAddress, setUserAddress] = useState("");
  const [userCity, setUserCity] = useState("");
  const [userState, setUserState] = useState("");
  const [userZip, setUserZip] = useState("");
  const [parsStart, setParsStart] = useState("");
  console.log("Pars Start: ", parsStart);
  const [papsStart, setPapsStart] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  let change = 0;
  const [statusEmail, setStatusEmail] = useState(false);
  const [filteredBorders, setFilteredBorders] = useState([]);

  const borders = [
    "Sumas, WA",
    "Blaine, WA",
    "Oroville, WA",
    "Eastport, ID",
    "Sweet Grass, MT",
    "Portal, ND",
    "Raymond, MT",
    "Pembina, ND",
    "Buffalo, NY",
    "Detroit, MI",
    "Port Huron, MI",
    "Ogdensburg, NY",
    "Champlain, NY",
    "Derby Line, VT",
    "Calais, ME",
    "Houlton, ME",
    "Huntingdon, BC",
    "Pacific Highway, BC",
    "Osoyoos, BC",
    "Kingsgate, BC",
    "Coutts, AB",
    "North Portal, SK",
    "Regway, SK",
    "Emerson, MB",
    "Fort Erie, ON",
    "Windsor, ON",
    "Sarnia, ON",
    "Prescott, ON",
    "Lacolle, QC",
    "Stanstead, QC",
    "St. Stephen, NB",
    "Woodstock, NB",
  ];

  // Function to map API response to form field names
  const mapApiResponse = (data) => {
    return {
      Number: data.Number || "",
      Border: data.Border || "",
      Broker: data.CustomsBroker || "",
      Email: data.BrokerEmail || "",
      Phone: data.BrokerPhone || "",
      LoadCompany: data.LoadCompany || "",
      Status: data.Status || "",
      PDF: data.PDF || "",
      UserId: data.UserId || "",
      Date: data.Date || "",
    };
  };

  const handleSearch = useCallback(async () => {
    if (!searchTerm) return; // Ensure there's something to search

    setIsLoading(true); // Start loading
    const prefix = searchTerm.substring(0, 4);
    console.log("Search term: ", searchTerm);
    setEmailPrefix(prefix === papsStart ? "PAPS" : "PARS");
    const apiUrl =
      (prefix === papsStart
        ? "/api/fetch-specific-paps"
        : "/api/fetch-specific-pars") +
      `?number=${searchTerm}&userId=${userId}`;

    try {
      const response = await fetch(apiUrl);

      if (response.ok) {
        const data = await response.json();
        console.log("Data fetched", data.result); // Debug: Check fetched data structure
        if (data.result) {
          // If result is an array, take the first item
          const result = Array.isArray(data.result)
            ? data.result[0]
            : data.result;
          const mappedData = mapApiResponse(result);
          setEditData(mappedData);
          setOriginalData(mappedData);
        } else {
          setEditData(null);
          alert("No data found for this number");
        }
      } else {
        // If response is not ok, throw an error to be caught below
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      alert("Error fetching data. Please try again.");
    } finally {
      setIsLoading(false);
    }
  }, [searchTerm, userId, papsStart]);

  useEffect(() => {
    const storedUserId = sessionStorage.getItem("storageUserId");
    const storedCompanyName = sessionStorage.getItem("storageCompanyName");
    const storedCustomsEmail = sessionStorage.getItem("storageCustomsEmail");
    const storedParsStart = sessionStorage.getItem("storageParsStart");
    const storedPapsStart = sessionStorage.getItem("storagePapsStart");
    const storedFirstName = sessionStorage.getItem("storageFirstName");
    const storedLastName = sessionStorage.getItem("storageLastName");
    const storedPhone = sessionStorage.getItem("storagePhone");
    const storedAddress = sessionStorage.getItem("storageAddress");
    const storedCity = sessionStorage.getItem("storageCity");
    const storedState = sessionStorage.getItem("storageState");
    const storedZip = sessionStorage.getItem("storageZip");
    const papsData = sessionStorage.getItem("papsData");
    const parsData = sessionStorage.getItem("parsData");

    if (storedUserId) {
      setUserId(storedUserId);
    }
    if (storedCompanyName) {
      setUserCompanyName(storedCompanyName);
    }
    if (storedCustomsEmail) {
      setUserCustomsEmail(storedCustomsEmail);
    }
    if (storedParsStart) {
      setParsStart(storedParsStart);
    }
    if (storedPapsStart) {
      setPapsStart(storedPapsStart);
    }
    if (storedFirstName) {
      setUserFirstName(storedFirstName);
    }
    if (storedLastName) {
      setUserLastName(storedLastName);
    }
    if (storedPhone) {
      setUserPhone(storedPhone);
    }
    if (storedAddress) {
      setUserAddress(storedAddress);
    }
    if (storedCity) {
      setUserCity(storedCity);
    }
    if (storedState) {
      setUserState(storedState);
    }
    if (storedZip) {
      setUserZip(storedZip);
    }

    // Ensure papsStart and parsStart are set before setting searchTerm
    if (papsData) {
      setSearchTerm(papsData);
    } else if (parsData) {
      setSearchTerm(parsData);
    }
  }, []);

  useEffect(() => {
    if (searchTerm && papsStart && parsStart) {
      handleSearch();
    }
  }, [searchTerm, handleSearch, papsStart, parsStart]);

  const generateBarcodeImage = async (data, mergedPdf) => {
    try {
      // Create a canvas element dynamically
      const canvas = document.createElement("canvas");
      // Generate barcode
      await bwipjs.toCanvas(canvas, {
        bcid: "code128",
        text: data,
        scale: 3,
        height: 10,
        includetext: true,
        textxalign: "center",
      });

      // Get the image data from the canvas
      const barcodeDataUrl = canvas.toDataURL("image/png");
      const barcodeImageBytes = await fetch(barcodeDataUrl).then((res) =>
        res.arrayBuffer()
      );

      // Embed the PNG image into the PDF
      return await mergedPdf.embedPng(barcodeImageBytes); // Pass mergedPdf correctly
    } catch (err) {
      console.error("Error generating barcode:", err);
      throw err;
    }
  };

  const downloadPdf = async (blobUrlWithoutToken) => {
    setIsLoading(true);
    const sasToken =
      "sp=racwd&st=2024-05-24T01:18:34Z&se=2025-09-01T09:18:34Z&spr=https&sv=2022-11-02&sr=c&sig=TmfTSU%2FdvAVNpmwoB2gHNJrq9NNejQgFNchUEn7OW0M%3D";
    const blobLink = "https://hokage.blob.core.windows.net/output/ AB-BC 800.00.pdf";
    const completeBlobUrl = `${blobLink}?${sasToken}`;

    try {
      const response = await fetch(completeBlobUrl);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const blob = await response.blob();
      const downloadUrl = window.URL.createObjectURL(blob);
      const decodedFileName = decodeURIComponent(
        blobUrlWithoutToken.split("/").pop()
      );

      const a = document.createElement("a");
      a.href = downloadUrl;
      a.download = decodedFileName;
      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(downloadUrl);
    } catch (error) {
      console.error("Failed to download file:", error);
      alert("Failed to download PDF.");
    }
    setIsLoading(false);
  };

  const editPdf = async (pdfUrl, originalData, editData) => {
    const sasToken =
      "sp=racwd&st=2024-05-24T01:18:34Z&se=2025-09-01T09:18:34Z&spr=https&sv=2022-11-02&sr=c&sig=TmfTSU%2FdvAVNpmwoB2gHNJrq9NNejQgFNchUEn7OW0M%3D";
    const completeBlobUrl = `${pdfUrl}?${sasToken}`;

    try {
      const response = await fetch(completeBlobUrl);
      if (!response.ok)
        throw new Error("Failed to download PDF for modification");

      const existingPdfBytes = await response.arrayBuffer();
      const pdfDoc = await PDFDocument.load(existingPdfBytes);

      // Remove the first page
      pdfDoc.removePage(0);

      // Create a new page
      const customPage = pdfDoc.insertPage(0);
      const { width, height } = customPage.getSize();
      const font = await pdfDoc.embedFont(StandardFonts.Helvetica);
      const fontSize = 18;

      // Prepare text content
      const currentDate = new Date();

      // Add 2 hours
      currentDate.setHours(currentDate.getHours() + 2);

      // Round minutes to the nearest hour (down to 0 or up to 60)
      currentDate.setMinutes(currentDate.getMinutes() >= 30 ? 60 : 0);

      const options = {
        day: "2-digit",
        month: "long",
        year: "numeric",
        hour: "numeric",
        minute: "2-digit",
        hour12: true,
      };

      const dateString = currentDate.toLocaleString("en-US", options);
      const prefix = editData.Number.startsWith(papsStart) ? "PAPS" : "PARS";
      let text1 = `${prefix} ${editData.Number}`;
      let text2 = `Crossing at ${editData.Border}`;
      let text3 = inRush
        ? `Please rush, driver is at border`
        : `on ${dateString}`;
      if (change === 2) {
        text1 = `${prefix} ${editData.Number}`;
        text2 = `Please change border from ${originalData.Border} to ${editData.Border}`;
        text3 = `Crossing on ${dateString}`;
        if (inRush) {
          text3 = `Kindly expedite, the driver is at the border.`;
        }
      } else if (change === 1) {
        text1 = `Please change ${prefix} ${originalData.Number} to ${editData.Number}`;
        text2 = `Crossing at ${editData.Border}`;
      } else if (statusEmail) {
        text3 = "Please provide the status for this shipment";
      } else if (statusEmail && inRush) {
        text3 =
          "Please provide the status for this shipment. Driver is at the border.";
      }

      const centerX = width / 2;
      const centerY = height / 2;

      const barcodeImage = await generateBarcodeImage(editData.Number, pdfDoc);
      if (!barcodeImage) {
        return;
      }
      const barcodeHeight = 60; // Height of the barcode image in points
      customPage.drawImage(barcodeImage, {
        x: centerX - barcodeImage.width / 2,
        y: centerY + barcodeHeight, // Position the barcode above text1
        width: barcodeImage.width,
        height: barcodeHeight,
      });

      const text1Width = font.widthOfTextAtSize(text1, fontSize);
      const text2Width = font.widthOfTextAtSize(text2, fontSize);
      const text3Width = font.widthOfTextAtSize(text3, fontSize);

      customPage.drawText(text1, {
        x: centerX - text1Width / 2,
        y: centerY + 20,
        size: fontSize,
        font,
      });
      customPage.drawText(text2, {
        x: centerX - text2Width / 2,
        y: centerY - 20,
        size: fontSize,
        font,
      });
      customPage.drawText(text3, {
        x: centerX - text3Width / 2,
        y: centerY - 60,
        size: fontSize,
        font,
      });

      const modifiedPdfBytes = await pdfDoc.save();
      const mergedPdfBlob = new Blob([modifiedPdfBytes], {
        type: "application/pdf",
      });
      return mergedPdfBlob;
    } catch (error) {
      console.error("Failed to modify PDF:", error);
      alert("Failed to modify PDF.");
      throw new Error("Failed to modify PDF");
    }
  };

  const blobToBase64 = (blob) => {
    const reader = new FileReader();
    return new Promise((resolve) => {
      reader.onloadend = () => {
        resolve(reader.result);
      };
      reader.readAsDataURL(blob);
    });
  };

  const uploadToBlobStorage = async (pdfBlob, fileName) => {
    try {
      const base64Content = await blobToBase64(pdfBlob);
      const base64Data = base64Content.replace(/^data:.+;base64,/, "");

      const body = JSON.stringify({
        blobName: fileName,
        blobContent: base64Data,
      });

      const response = await fetch("/api/save-to-blob", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: body,
      });

      const data = await response.json();
      if (response.ok) {
        console.log("PDF uploaded successfully: ", data.url);
        return data.url;
      } else {
        throw new Error(data.error || "Failed to upload PDF");
      }
    } catch (error) {
      console.error("Error uploading PDF to blob storage: ", error);
      alert("Failed to upload PDF to blob storage.");
      return null;
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(`Field updated - ${name}: ${value}`); // Debug: Check which field is updated
    setEditData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    if (name === "Broker") {
      fetchBrokerEmailz(value, emailPrefix);
    }
  };

  const fetchBrokerEmailz = async (brokerName, prefix) => {
    let toEmail = "";
    const brokerData = await fetchBrokerByName(brokerName);
    if (!brokerData || brokerData.length === 0) {
      setEditData((prevData) => ({
        ...prevData,
        Email: toEmail,
      }));
      return;
    }
    const broker = brokerData[0];
    if (prefix === "PARS") {
      toEmail = broker.parsEmail || "";
    } else if (prefix === "PAPS") {
      toEmail = broker.papsEmail || "";
    }
    setEditData((prevData) => ({
      ...prevData,
      Email: toEmail,
      Phone: broker.brokerPhone || "",
    }));
  };

  const fetchBrokerByName = async (brokerName) => {
    try {
      const endpoint = `/api/fetch-broker`;
      const response = await fetch(
        `${endpoint}?name=${encodeURIComponent(brokerName)}`
      );
      if (!response.ok) {
        throw new Error("Failed to fetch broker");
      }
      const data = await response.json();
      return data.result;
    } catch (error) {
      console.error("Error fetching broker:", error);
      alert("Failed to fetch broker information.");
      return null;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    console.log(`Driver in rush status: ${inRush}`);
    try {
      if (
        originalData &&
        editData.Number !== originalData.Number &&
        editData.Border === originalData.Border
      ) {
        change = 1;
        // Cancel original record
        const cancelResponse = await fetch(
          originalData.Number.substring(0, 4) === papsStart
            ? "/api/update-paps"
            : "/api/update-pars",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              ...originalData,
              Status: "Cancelled",
              UserId: userId,
            }),
          }
        );

        if (!cancelResponse.ok) {
          throw new Error("Failed to update original record");
        }

        // Edit PDF and upload
        const modifiedPdfBlob = await editPdf(
          originalData.PDF,
          originalData,
          editData
        );
        const newPdfUrl = await uploadToBlobStorage(
          modifiedPdfBlob,
          `${editData.Number}-${editData.Border}.pdf`
        );

        // Create new record
        const newData = {
          ...editData,
          PDF: newPdfUrl,
          UserId: userId,
          Status: "Active",
        };
        const createResponse = await fetch(
          editData.Number.substring(0, 4) === papsStart
            ? "/api/update-paps"
            : "/api/update-pars",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(newData),
          }
        );

        if (!createResponse.ok) {
          throw new Error("Failed to create new record");
        }

        // Send email
        await sendEmailWithAttachment(
          editData.Email,
          `${emailPrefix} ${originalData.Number} change to ${editData.Number} ${editData.Border}`,
          modifiedPdfBlob,
          `Please change ${originalData.Number} to ${editData.Number} at ${editData.Border}.\n\nThank you.`
        );

        alert("Update successful with new number creation");
      } else if (
        originalData &&
        editData.Border !== originalData.Border &&
        editData.Number === originalData.Number
      ) {
        change = 2;
        // Edit PDF and upload
        const modifiedPdfBlob = await editPdf(
          originalData.PDF,
          originalData,
          editData
        );
        const newPdfUrl = await uploadToBlobStorage(
          modifiedPdfBlob,
          `${editData.Number}-${editData.Border}.pdf`
        );

        // Create new record
        const newData = {
          ...editData,
          PDF: newPdfUrl,
          UserId: userId,
          Status: "Active",
        };
        const createResponse = await fetch(
          editData.Number.substring(0, 4) === papsStart
            ? "/api/update-paps"
            : "/api/update-pars",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(newData),
          }
        );

        if (!createResponse.ok) {
          throw new Error("Failed to create new record");
        }

        // Send email
        await sendEmailWithAttachment(
          editData.Email,
          `${emailPrefix} ${editData.Number} ${editData.Border}`,
          modifiedPdfBlob,
          `For ${editData.Number} please change border from ${originalData.Border} to ${editData.Border}\n\nThank you\n${userCompanyName}`
        );

        alert("Border change request sent successfully");
      } else if (
        originalData &&
        (editData.Number !== originalData.Number ||
          editData.Border !== originalData.Border ||
          editData.Email !== originalData.Email)
      ) {
        change = 3;
        // Edit PDF and upload
        const modifiedPdfBlob = await editPdf(
          originalData.PDF,
          originalData,
          editData
        );
        const newPdfUrl = await uploadToBlobStorage(
          modifiedPdfBlob,
          `${editData.Number}-${editData.Border}.pdf`
        );

        // Create new record
        const newData = {
          ...editData,
          PDF: newPdfUrl,
          UserId: userId,
          Status: "Active",
        };
        const createResponse = await fetch(
          editData.Number.substring(0, 4) === papsStart
            ? "/api/update-paps"
            : "/api/update-pars",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(newData),
          }
        );

        if (!createResponse.ok) {
          throw new Error("Failed to create new record");
        }

        // Send email
        await sendEmailWithAttachment(
          editData.Email,
          `${emailPrefix} ${editData.Number} ${editData.Border}`,
          modifiedPdfBlob,
          `Thank you\n${userCompanyName}`
        );

        alert("Custom Docs sent to new email successfully");
      } else {
        // Update existing record
        const response = await fetch(
          editData.Number.substring(0, 4) === papsStart
            ? "/api/update-paps"
            : "/api/update-pars",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ ...editData, UserId: userId }),
          }
        );

        if (!response.ok) {
          throw new Error("Failed to update");
        }

        if (statusEmail) {
          alert("Status email sent successfully");
        } else {
          alert("Update successful");
        }
      }
    } catch (error) {
      console.error("Error handling submit:", error);
      alert("An error occurred while processing your request.");
    }
    setIsLoading(false);
  };

  const handleBorderChange = (event) => {
    const input = event.target.value;
    setEditData({ ...editData, Border: input });
    if (!input) {
      setFilteredBorders([]);
    } else {
      setFilteredBorders(
        borders.filter((border) =>
          border.toLowerCase().includes(input.toLowerCase())
        )
      );
    }
  };

  const selectBorder = (border) => {
    setEditData({ ...editData, Border: border });
    setFilteredBorders([]);
  };

  const Dropdown = ({ items, onSelect }) => {
    return (
      <div className="dropdown">
        {items.map((item, index) => (
          <div
            key={index}
            onClick={() => onSelect(item)}
            className="dropdown-item"
          >
            {item}
          </div>
        ))}
      </div>
    );
  };

  const sendStatusEmail = async () => {
    setIsLoading(true);
    setStatusEmail(true);
    try {
      const modifiedPdfBlob = await editPdf(
        originalData.PDF,
        originalData,
        editData
      );
      await sendEmailWithAttachment(
        editData.Email,
        `${emailPrefix} ${editData.Number} ${editData.Border}`,
        modifiedPdfBlob,
        `Please provide the status for ${emailPrefix} number ${
          editData.Number
        }. ${
          inRush ? "The driver is at the border." : ""
        }\n\nThank you,\n${userCompanyName}`
      );
      alert("Status email sent successfully");
    } catch (error) {
      console.error("Error sending status email:", error);
      alert("Failed to send status email.");
    }
    setIsLoading(false);
  };

  const sendEmailWithAttachment = async (
    toEmail,
    subject,
    attachment,
    body
  ) => {
    const emails = toEmail.split(",");
    const primaryEmail = emails.shift();
    const ccEmails = emails.join(",");
    const replyTo = userCustomsEmail;
    const company = userCompanyName;
    const formData = new FormData();
    formData.append("toEmail", primaryEmail);
    formData.append("ccEmails", ccEmails);
    formData.append("firstName", userFirstName);
    formData.append("lastName", userLastName);
    formData.append("phone", userPhone);
    formData.append("subject", subject);
    formData.append("body", body);
    formData.append("attachment", attachment);
    formData.append("replyTo", replyTo);
    formData.append("companyName", company);
    formData.append("storageUserId", userId);
    formData.append("number", editData.Number);
    formData.append("address", userAddress);
    formData.append("city", userCity);
    formData.append("state", userState);
    formData.append("zip", userZip);
    try {
      const response = await fetch("/api/send-email-with-attachment", {
        method: "POST",
        body: formData,
      });
      const data = await response.json();
      if (response.ok) {
        console.log("Email sent successfully: ", data.message);
      } else {
        throw new Error(data.error || "Failed to send email");
      }
    } catch (error) {
      console.error("Error sending email:", error);
      alert("Failed to send email.");
    }
  };

  return (
    <div className="container">
      <SideNavBar />
      <div className="edit-window">
        <Toolbar style={{ minHeight: "83px" }} />
        <div className="edit-body">
          {isLoading && <Spinner />}
          {!isLoading && editData && (
            <form onSubmit={handleSubmit} className="form-section">
              <div className="form-group">
                <label htmlFor="Number">Number</label>
                <TextField
                  type="text"
                  name="Number"
                  value={editData.Number}
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    style: {
                      color: "white",
                    },
                  }}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="Border">Border</label>
                <TextField
                  type="text"
                  name="Border"
                  value={editData.Border}
                  onChange={handleBorderChange}
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    style: {
                      color: "white",
                    },
                  }}
                  autoComplete="off"
                  required
                />
                {filteredBorders.length > 0 && (
                  <Dropdown items={filteredBorders} onSelect={selectBorder} />
                )}
              </div>
              <div className="form-group">
                <label htmlFor="LoadCompany">Company Name</label>
                <TextField
                  type="text"
                  name="LoadCompany"
                  value={editData.LoadCompany}
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    style: {
                      color: "white",
                    },
                  }}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="Broker">Broker</label>
                <TextField
                  type="text"
                  name="Broker"
                  value={editData.Broker}
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    style: {
                      color: "white",
                    },
                  }}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="Email">Email</label>
                <TextField
                  type="email"
                  name="Email"
                  value={editData.Email}
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    style: {
                      color: "white",
                      border: "1px solid #3c94fc",
                    },
                  }}
                  multiline
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="Phone">Phone</label>
                <TextField
                  type="tel"
                  name="Phone"
                  value={editData.Phone}
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    style: {
                      color: "white",
                    },
                  }}
                />
              </div>
              <div className="form-group">
                <label htmlFor="Status">Status</label>
                <TextField
                  type="text"
                  name="Status"
                  value={editData.Status}
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    style: {
                      color: "white",
                    },
                  }}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="Date">Date</label>
                <TextField
                  type="text"
                  name="Date"
                  value={editData.Date}
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    style: {
                      color: "white",
                    },
                  }}
                  required
                />
              </div>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={inRush}
                    onChange={() => setInRush(!inRush)}
                    name="inRush"
                    color="primary"
                    sx={{ color: "white" }}
                  />
                }
                label="Driver in Rush"
              />
              <div className="button-group">
                <Button
                  type="submit"
                  disabled={isLoading}
                  variant="contained"
                  color="primary"
                >
                  {isLoading ? <Spinner /> : "Update"}
                </Button>
              </div>
              <div className="button-group">
                <Button
                  onClick={() => downloadPdf(editData.PDF)}
                  disabled={isLoading}
                  variant="outlined"
                  color="secondary"
                >
                  {isLoading ? <Spinner /> : "Download PDF"}
                </Button>
              </div>
              <div className="button-group">
                <Button
                  onClick={sendStatusEmail}
                  disabled={isLoading}
                  variant="outlined"
                  color="info"
                >
                  {isLoading ? <Spinner /> : "Send Status Email"}
                </Button>
              </div>
            </form>
          )}
          {!isLoading && !editData && (
            <div className="no-data">
              <p>No data available. Please search for a valid number.</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Edit;
