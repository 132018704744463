import React, { useState, useEffect } from "react";
import {
  Card,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Toolbar,
  Typography,
  Pagination,
  Paper,
  Box,
  Button,
} from "@mui/material";
import Spinner from "./Spinner";
import SideNavBar from "./SideNavBar";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import "./CreateInvoice.css"
// import { useNavigate } from "react-router-dom";

function CreateInvoice() {
  const [loads, setLoads] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
//   const navigate = useNavigate();

  useEffect(() => {
    const fetchDeliveredLoads = async () => {
      const storedUserId = sessionStorage.getItem("storageUserId");
      setIsLoading(true);

      try {
        // Fetch loads with status=Delivered
        const response = await fetch(
          `/api/fetch-loads?userId=${encodeURIComponent(
            storedUserId
          )}&status=Delivered`,
          {
            method: "GET",
            headers: { "Content-Type": "application/json" },
          }
        );
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();

        // Normalize status values if necessary
        const normalizedData = data.result.map((item) => ({
          ...item,
          Status: item.Status || "None",
        }));
        setLoads(normalizedData);
      } catch (error) {
        console.error("Failed to fetch delivered Loads data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchDeliveredLoads();
  }, []);

  // Handle search input change
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1); // Reset to first page on search
  };

  // Filter based on search term
  const filteredItems = loads.filter((item) => {
    const searchLower = searchTerm.toLowerCase();
    return (
      item.CustomerOrderNumber?.toString()
        .toLowerCase()
        .includes(searchLower) ||
      item.OrderNumber?.toLowerCase().includes(searchLower) ||
      item.Company?.toLowerCase().includes(searchLower) ||
      item.PickupDate?.toLowerCase().includes(searchLower) ||
      item.PickupLocation?.toLowerCase().includes(searchLower) ||
      item.DeliveryDate?.toLowerCase().includes(searchLower) ||
      item.DeliveryLocation?.toLowerCase().includes(searchLower) ||
      item.CarrierOrEquipment?.toLowerCase().includes(searchLower) ||
      item.Status?.toLowerCase().includes(searchLower)
    );
  });

  // Pagination
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredItems.slice(indexOfFirstItem, indexOfLastItem);

  const darkTheme = createTheme({
    palette: {
      mode: "dark",
      background: {
        default: "#000000",
        paper: "#000000",
      },
      text: {
        primary: "#ffffff",
        secondary: "#aaaaaa",
      },
      primary: {
        main: "#3c94fc",
      },
    },
  });

  // Handle Create Invoice
  const handleCreateInvoice = (load) => {
    // You can store necessary info in session or local storage
    //sessionStorage.setItem("selectedLoadNumber", load.CustomerOrderNumber);
    // Navigate to some invoice form page or do further logic
    // For now, just alert or navigate:
    alert(`Creating invoice for load #${load.CustomerOrderNumber}`);
    // navigate("/your-invoice-page");
  };

  return (
    <ThemeProvider theme={darkTheme}>
      <div className="create-invoice-container">
        <SideNavBar />
        <div className="create-invoice-window">
          <Toolbar style={{ minHeight: "83px" }} />
          <div className="create-invoice-body">
            <Card sx={{ maxWidth: "100%", overflowX: "auto", m: 2 }}>
              <Typography
                variant="h4"
                component="div"
                sx={{
                  m: 2,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                Delivered Orders
              </Typography>

              {/* Search Field */}
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                width="100%"
              >
                <TextField
                  variant="outlined"
                  value={searchTerm}
                  onChange={handleSearchChange}
                  placeholder="Search by any column"
                  margin="normal"
                  sx={{
                    m: 2,
                    width: "50%",
                    input: { color: "#fff" },
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "#555",
                      },
                      "&:hover fieldset": {
                        borderColor: "#777",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#3c94fc",
                      },
                    },
                  }}
                  InputLabelProps={{
                    style: { color: "#fff" },
                  }}
                />
              </Box>

              {isLoading ? (
                <Box
                  sx={{
                    m: 2,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  <Spinner sx={{ color: "#3c94fc" }} />
                </Box>
              ) : (
                <TableContainer component={Paper}>
                  <Table stickyHeader aria-label="delivered orders table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Customer Order#</TableCell>
                        <TableCell>Order#</TableCell>
                        <TableCell>Company</TableCell>
                        <TableCell>Pickup Date</TableCell>
                        <TableCell>Pickup Location</TableCell>
                        <TableCell>Delivery Date</TableCell>
                        <TableCell>Delivery Location</TableCell>
                        <TableCell>Carrier/Equipment</TableCell>
                        <TableCell>Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {currentItems.length > 0 ? (
                        currentItems.map((item, index) => (
                          <TableRow key={index}>
                            <TableCell>{item.CustomerOrderNumber}</TableCell>
                            <TableCell>{item.OrderNumber || "N/A"}</TableCell>
                            <TableCell>{item.Company}</TableCell>
                            <TableCell>
                              {new Date(item.PickupDate).toLocaleDateString()}
                            </TableCell>
                            <TableCell>{item.PickupLocation}</TableCell>
                            <TableCell>
                              {new Date(item.DeliveryDate).toLocaleDateString()}
                            </TableCell>
                            <TableCell>{item.DeliveryLocation}</TableCell>
                            <TableCell>{item.CarrierOrEquipment}</TableCell>
                            <TableCell>
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={() => handleCreateInvoice(item)}
                              >
                                Send POD
                              </Button>
                            </TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <TableRow>
                          <TableCell colSpan={11} align="center">
                            No delivered orders found
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                  <Pagination
                    count={Math.ceil(filteredItems.length / itemsPerPage)}
                    page={currentPage}
                    onChange={(event, value) => setCurrentPage(value)}
                    color="primary"
                    sx={{
                      py: 2,
                      display: "flex",
                      justifyContent: "center",
                    }}
                    showFirstButton
                    showLastButton
                  />
                </TableContainer>
              )}
            </Card>
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
}

export default CreateInvoice;
