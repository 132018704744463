import React from "react";
import { createRoot } from "react-dom/client"; // Updated import
import "./index.css";
import App from "./App";
import { PublicClientApplication, EventType } from "@azure/msal-browser";
import { msalConfig } from "./authConfig";

// Initialize MSAL
const msalInstance = new PublicClientApplication(msalConfig);

// Set the first account as active if none are currently active
if (
  !msalInstance.getActiveAccount() &&
  msalInstance.getAllAccounts().length > 0
) {
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

// Handle authentication events
msalInstance.addEventCallback((event) => {
  if (
    (event.eventType === EventType.LOGIN_SUCCESS ||
      event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
      event.eventType === EventType.SSO_SILENT_SUCCESS) &&
    event.payload.account
  ) {
    msalInstance.setActiveAccount(event.payload.account);
  }
});

// Get the root DOM element
const container = document.getElementById("root");

// Create a root
const root = createRoot(container);

// Render the App component, passing the MSAL instance
root.render(
  <React.StrictMode>
    <App instance={msalInstance} />
  </React.StrictMode>
);
