import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Toolbar,
  FormControl,
  FormHelperText,
  Box,
  Typography,
  Paper,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Pagination,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import Spinner from "./Spinner";
import SideNavBar from "./SideNavBar";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import "./EditTruck.css";

function EditTruck() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [truckData, setTruckData] = useState(null);
  const [errors, setErrors] = useState({});
  const [activeTab, setActiveTab] = useState("info");

  // State for editable fields
  const [number, setNumber] = useState("");
  const [make, setMake] = useState("");
  const [model, setModel] = useState("");
  const [licensePlate, setLicensePlate] = useState("");

  // Expense States
  const [expenses, setExpenses] = useState([]);
  const [expenseSearchTerm, setExpenseSearchTerm] = useState("");
  const [filteredExpenses, setFilteredExpenses] = useState([]);
  const [currentExpensePage, setCurrentExpensePage] = useState(1);
  const expensesPerPage = 10;

  // Add Expense Dialog States
  const [isAddExpenseDialogOpen, setIsAddExpenseDialogOpen] = useState(false);
  const [newExpense, setNewExpense] = useState({
    Type: "",
    Price: "",
    Currency: "",
  });
  const [expenseErrors, setExpenseErrors] = useState({});

  // Edit Expense Dialog States
  const [isEditExpenseDialogOpen, setIsEditExpenseDialogOpen] = useState(false);
  const [selectedExpenseToEdit, setSelectedExpenseToEdit] = useState(null);
  const [editExpense, setEditExpense] = useState({
    Type: "",
    Price: "",
    Currency: "",
  });

  // Delete Expense Confirmation Dialog States
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [expenseToDelete, setExpenseToDelete] = useState(null);

  // Define the dark theme
  const darkTheme = createTheme({
    palette: {
      mode: "dark",
      background: {
        default: "#000000",
        paper: "#000000",
      },
      text: {
        primary: "#ffffff",
        secondary: "#aaaaaa",
      },
      primary: {
        main: "#3c94fc",
      },
    },
  });

  useEffect(() => {
    // Initialize fake truck data if not present
    const storedTruckData = sessionStorage.getItem("truckData");
    if (!storedTruckData) {
      const fakeTruckData = {
        Id: "truck-123",
        Number: "TRK-001",
        Make: "Volvo",
        Model: "FH16",
        LicensePlate: "ABC-1234",
        UserId: "user-456",
      };
      sessionStorage.setItem("truckData", JSON.stringify(fakeTruckData));
    }

    const parsedData = JSON.parse(sessionStorage.getItem("truckData"));
    setTruckData(parsedData);
    setNumber(parsedData.Number || "");
    setMake(parsedData.Make || "");
    setModel(parsedData.Model || "");
    setLicensePlate(parsedData.LicensePlate || "");

    fetchExpenses(parsedData.Id);
  }, [navigate]);

  // Fetch Expenses
  const fetchExpenses = async (truckId) => {
    setIsLoading(true);
    try {
      const response = await fetch(
        `/api/fetch-truck-expenses?truckId=${encodeURIComponent(truckId)}`,
        {
          method: "GET",
          headers: { "Content-Type": "application/json" },
        }
      );
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      setExpenses(data.result);
      setFilteredExpenses(data.result);
    } catch (error) {
      console.error("Failed to fetch expenses:", error);
      alert(`Error fetching expenses: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  // Handle ToggleButtonGroup change
  const handleTabChange = (event, newTab) => {
    if (newTab !== null) {
      setActiveTab(newTab);
    }
  };

  // Handle form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "Number":
        setNumber(value);
        break;
      case "Make":
        setMake(value);
        break;
      case "Model":
        setModel(value);
        break;
      case "LicensePlate":
        setLicensePlate(value);
        break;
      default:
        break;
    }
  };

  // Validate form inputs
  const validate = () => {
    const newErrors = {};
    if (!number.trim()) newErrors.Number = "Number is required.";
    if (!make.trim()) newErrors.Make = "Make is required.";
    if (!model.trim()) newErrors.Model = "Model is required.";
    if (!licensePlate.trim())
      newErrors.LicensePlate = "License Plate is required.";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validate()) return;

    setIsLoading(true);
    try {
      const response = await fetch("/api/update-truck", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          Id: truckData.Id,
          Number: number,
          Make: make,
          Model: model,
          LicensePlate: licensePlate,
          UserId: truckData.UserId,
        }),
      });

      console.log("Response Status:", response.status);
      console.log("Response Headers:", response.headers);

      const contentType = response.headers.get("Content-Type");
      console.log("Content-Type:", contentType);

      if (!response.ok) {
        let errorMessage = `HTTP error! Status: ${response.status}`;
        if (contentType && contentType.includes("application/json")) {
          const errorData = await response.json();
          errorMessage = errorData.error || errorMessage;
        } else {
          const errorText = await response.text();
          errorMessage = errorText || errorMessage;
        }
        throw new Error(errorMessage);
      }

      if (contentType && contentType.includes("application/json")) {
        const data = await response.json();
        console.log("Update Truck Response:", data);
        alert("Truck updated successfully!");
        navigate("/trucks");
      } else {
        const text = await response.text();
        console.warn("Unexpected response format:", text);
        throw new Error("Unexpected response format.");
      }
    } catch (error) {
      console.error("Failed to update Truck:", error);
      alert(`Error updating Truck: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  // Handle cancel button
  const handleCancel = () => {
    navigate("/trucks");
  };

  // Expense Handlers (Search)
  const handleExpenseSearchChange = (e) => {
    const term = e.target.value;
    setExpenseSearchTerm(term);
    if (term.trim() === "") {
      setFilteredExpenses(expenses);
    } else {
      const lowerTerm = term.toLowerCase();
      const filtered = expenses.filter((expense) =>
        Object.values(expense).some((val) =>
          String(val).toLowerCase().includes(lowerTerm)
        )
      );
      setFilteredExpenses(filtered);
      setCurrentExpensePage(1);
    }
  };

  // Validate Expense (can be reused for both add and edit)
  const validateExpense = (expenseObj) => {
    const newErrors = {};
    if (!expenseObj.Type.trim()) newErrors.Type = "Type is required.";
    if (!expenseObj.Price.trim()) {
      newErrors.Price = "Price is required.";
    } else if (isNaN(expenseObj.Price)) {
      newErrors.Price = "Price must be a number.";
    }
    if (!expenseObj.Currency.trim())
      newErrors.Currency = "Currency is required.";
    setExpenseErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // ---- Add Expense ----
  const handleOpenAddExpenseDialog = () => {
    setNewExpense({ Type: "", Price: "", Currency: "" });
    setExpenseErrors({});
    setIsAddExpenseDialogOpen(true);
  };

  const handleCloseAddExpenseDialog = () => {
    setIsAddExpenseDialogOpen(false);
  };

  const handleAddExpenseInputChange = (e) => {
    const { name, value } = e.target;
    setNewExpense((prev) => ({ ...prev, [name]: value }));
  };

  const handleAddExpenseSubmit = async () => {
    if (!validateExpense(newExpense)) return;

    setIsLoading(true);
    try {
      const response = await fetch("/api/add-truck-expense", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          Type: newExpense.Type,
          Price: parseFloat(newExpense.Price),
          Currency: newExpense.Currency,
          TruckId: truckData.Id,
          UserId: truckData.UserId,
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(
          errorData.error || `HTTP error! Status: ${response.status}`
        );
      }

      const addedExpense = await response.json();
      setExpenses((prev) => [...prev, addedExpense]);
      setFilteredExpenses((prev) => [...prev, addedExpense]);
      setIsAddExpenseDialogOpen(false);
      alert("Expense added successfully!");
    } catch (error) {
      console.error("Failed to add expense:", error);
      alert(`Error adding expense: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  // ---- Edit Expense ----
  const handleEditExpenseClick = (expense) => {
    setSelectedExpenseToEdit(expense);
    // Pre-load the dialog with existing data
    setEditExpense({
      Type: expense.Type,
      Price: String(expense.Price),
      Currency: expense.Currency,
    });
    setExpenseErrors({});
    setIsEditExpenseDialogOpen(true);
  };

  const handleCloseEditExpenseDialog = () => {
    setIsEditExpenseDialogOpen(false);
    setSelectedExpenseToEdit(null);
    setEditExpense({ Type: "", Price: "", Currency: "" });
  };

  const handleEditExpenseInputChange = (e) => {
    const { name, value } = e.target;
    setEditExpense((prev) => ({ ...prev, [name]: value }));
  };

  const handleEditExpenseSubmit = async () => {
    if (!selectedExpenseToEdit) return;
    if (!validateExpense(editExpense)) return;

    setIsLoading(true);
    try {
      const response = await fetch("/api/update-truck-expense", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          Id: selectedExpenseToEdit.Id,
          Type: editExpense.Type,
          Price: parseFloat(editExpense.Price),
          Currency: editExpense.Currency,
          TruckId: truckData.Id,
          UserId: truckData.UserId,
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(
          errorData.error || `HTTP error! Status: ${response.status}`
        );
      }

      const updatedExpense = await response.json();
      // Update local state
      setExpenses((prev) =>
        prev.map((expense) =>
          expense.Id === updatedExpense.Id ? updatedExpense : expense
        )
      );
      setFilteredExpenses((prev) =>
        prev.map((expense) =>
          expense.Id === updatedExpense.Id ? updatedExpense : expense
        )
      );

      setIsEditExpenseDialogOpen(false);
      alert("Expense updated successfully!");
    } catch (error) {
      console.error("Failed to update expense:", error);
      alert(`Error updating expense: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  // ---- Delete Expense ----
  const handleDeleteExpenseClick = (expense) => {
    setExpenseToDelete(expense);
    setIsDeleteDialogOpen(true);
  };

  const handleConfirmDeleteExpense = async () => {
    if (!expenseToDelete) return;

    setIsLoading(true);
    try {
      const response = await fetch("/api/delete-truck-expense", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ Id: expenseToDelete.Id }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(
          errorData.error || `HTTP error! Status: ${response.status}`
        );
      }

      setExpenses((prev) =>
        prev.filter((expense) => expense.Id !== expenseToDelete.Id)
      );
      setFilteredExpenses((prev) =>
        prev.filter((expense) => expense.Id !== expenseToDelete.Id)
      );
      alert("Expense deleted successfully!");
    } catch (error) {
      console.error("Failed to delete expense:", error);
      alert(`Error deleting expense: ${error.message}`);
    } finally {
      setIsLoading(false);
      setIsDeleteDialogOpen(false);
      setExpenseToDelete(null);
    }
  };

  const handleCancelDeleteExpense = () => {
    setIsDeleteDialogOpen(false);
    setExpenseToDelete(null);
  };

  // Pagination
  const indexOfLastExpense = currentExpensePage * expensesPerPage;
  const indexOfFirstExpense = indexOfLastExpense - expensesPerPage;
  const currentExpenses = filteredExpenses.slice(
    indexOfFirstExpense,
    indexOfLastExpense
  );

  if (!truckData) {
    return (
      <ThemeProvider theme={darkTheme}>
        <div className="container">
          <SideNavBar />
          <div className="edit-truck-window">
            <Toolbar style={{ minHeight: "83px" }} />
            <div className="edit-truck-body">
              <Typography variant="h6">Loading truck data...</Typography>
              <Spinner />
            </div>
          </div>
        </div>
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider theme={darkTheme}>
      <div className="container">
        <SideNavBar />
        <div className="edit-truck-window">
          <Toolbar style={{ minHeight: "83px" }} />
          <div className="edit-truck-body">
            {/* ToggleButtonGroup for Tabs */}
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              width="100%"
              sx={{ mb: 2 }}
            >
              <ToggleButtonGroup
                value={activeTab}
                exclusive
                onChange={handleTabChange}
                aria-label="edit truck tabs"
                sx={{
                  backgroundColor: "#1a1a1a",
                  borderRadius: "4px",
                }}
              >
                <ToggleButton
                  value="info"
                  aria-label="Info"
                  sx={{ color: "#fff" }}
                >
                  Info
                </ToggleButton>
                <ToggleButton
                  value="expense"
                  aria-label="Expense"
                  sx={{ color: "#fff" }}
                >
                  Expense
                </ToggleButton>
              </ToggleButtonGroup>
            </Box>

            {/* --------- INFO TAB --------- */}
            {activeTab === "info" && (
              <Box p={3}>
                <Typography
                  variant="h4"
                  component="h1"
                  gutterBottom
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  Edit Truck Information
                </Typography>
                <form onSubmit={handleSubmit} className="edit-truck-form">
                  <Box
                    display="flex"
                    flexDirection="column"
                    gap={2}
                    alignItems="center"
                  >
                    <FormControl
                      error={Boolean(errors.Number)}
                      sx={{ width: "60%" }}
                    >
                      <label htmlFor="Number">Number</label>
                      <TextField
                        name="Number"
                        value={number}
                        onChange={handleInputChange}
                        variant="outlined"
                        required
                        style={{
                          marginTop: "10px",
                        }}
                        InputProps={{
                          style: { color: "#fff" },
                        }}
                        InputLabelProps={{
                          style: { color: "#fff" },
                        }}
                      />
                      {errors.Number && (
                        <FormHelperText>{errors.Number}</FormHelperText>
                      )}
                    </FormControl>

                    <FormControl
                      error={Boolean(errors.Make)}
                      sx={{ width: "60%" }}
                    >
                      <label htmlFor="Make">Make</label>
                      <TextField
                        name="Make"
                        value={make}
                        onChange={handleInputChange}
                        variant="outlined"
                        required
                        style={{
                          marginTop: "10px",
                        }}
                        InputProps={{
                          style: { color: "#fff" },
                        }}
                        InputLabelProps={{
                          style: { color: "#fff" },
                        }}
                      />
                      {errors.Make && (
                        <FormHelperText>{errors.Make}</FormHelperText>
                      )}
                    </FormControl>

                    <FormControl
                      error={Boolean(errors.Model)}
                      sx={{ width: "60%" }}
                    >
                      <label htmlFor="Model">Model</label>
                      <TextField
                        name="Model"
                        value={model}
                        onChange={handleInputChange}
                        variant="outlined"
                        required
                        style={{
                          marginTop: "10px",
                        }}
                        InputProps={{
                          style: { color: "#fff" },
                        }}
                        InputLabelProps={{
                          style: { color: "#fff" },
                        }}
                      />
                      {errors.Model && (
                        <FormHelperText>{errors.Model}</FormHelperText>
                      )}
                    </FormControl>

                    <FormControl
                      error={Boolean(errors.LicensePlate)}
                      sx={{ width: "60%" }}
                    >
                      <label htmlFor="LicensePlate">License Plate</label>
                      <TextField
                        name="LicensePlate"
                        value={licensePlate}
                        onChange={handleInputChange}
                        variant="outlined"
                        required
                        style={{
                          marginTop: "10px",
                        }}
                        InputProps={{
                          style: { color: "#fff" },
                        }}
                        InputLabelProps={{
                          style: { color: "#fff" },
                        }}
                      />
                      {errors.LicensePlate && (
                        <FormHelperText>{errors.LicensePlate}</FormHelperText>
                      )}
                    </FormControl>
                  </Box>

                  <Box display="flex" justifyContent="center" gap={2} mt={3}>
                    <Button
                      type="submit"
                      variant="outlined"
                      color="primary"
                      disabled={isLoading}
                      startIcon={<EditIcon />}
                    >
                      {isLoading ? <Spinner size="small" /> : "Save Changes"}
                    </Button>
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={handleCancel}
                      disabled={isLoading}
                      startIcon={<DeleteIcon />}
                    >
                      Cancel
                    </Button>
                  </Box>
                </form>
              </Box>
            )}

            {/* --------- EXPENSE TAB --------- */}
            {activeTab === "expense" && (
              <Box p={3}>
                <Typography
                  variant="h4"
                  component="h1"
                  gutterBottom
                  display="flex"
                  justifyContent="center"
                >
                  Truck Expenses
                </Typography>

                {/* Search and Add Expense Button */}
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  mb={2}
                >
                  <TextField
                    variant="outlined"
                    value={expenseSearchTerm}
                    onChange={handleExpenseSearchChange}
                    placeholder="Search Expenses"
                    size="small"
                    sx={{ width: "40%", marginRight: "20px" }}
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<AddIcon />}
                    onClick={handleOpenAddExpenseDialog}
                    style={{
                      backgroundColor: "transparent",
                      color: "#fff",
                      border: "1px solid #3c94fc",
                      borderRadius: "15px",
                      padding: "10px 20px",
                    }}
                  >
                    Add Expense
                  </Button>
                </Box>

                {isLoading ? (
                  <Box
                    sx={{
                      m: 2,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      textAlign: "center",
                    }}
                  >
                    <Spinner sx={{ color: "#3c94fc" }} />
                  </Box>
                ) : (
                  <>
                    <TableContainer component={Paper}>
                      <Table stickyHeader aria-label="expenses table">
                        <TableHead>
                          <TableRow>
                            <TableCell>Type</TableCell>
                            <TableCell>Price</TableCell>
                            <TableCell>Currency</TableCell>
                            <TableCell>Action</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {currentExpenses.length > 0 ? (
                            currentExpenses.map((expense) => (
                              <TableRow key={expense.Id}>
                                <TableCell>{expense.Type}</TableCell>
                                <TableCell>{expense.Price}</TableCell>
                                <TableCell>{expense.Currency}</TableCell>
                                <TableCell>
                                  {/* Edit Button */}
                                  <IconButton
                                    onClick={() =>
                                      handleEditExpenseClick(expense)
                                    }
                                    sx={{ color: "#3c94fc" }}
                                    aria-label="edit"
                                  >
                                    <EditIcon />
                                  </IconButton>
                                  {/* Delete Button */}
                                  <IconButton
                                    onClick={() =>
                                      handleDeleteExpenseClick(expense)
                                    }
                                    sx={{ color: "#f44336" }}
                                    aria-label="delete"
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                            ))
                          ) : (
                            <TableRow>
                              <TableCell colSpan={4} align="center">
                                No expenses found
                              </TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>

                      {/* Pagination */}
                      <Pagination
                        count={Math.ceil(
                          filteredExpenses.length / expensesPerPage
                        )}
                        page={currentExpensePage}
                        onChange={(event, value) =>
                          setCurrentExpensePage(value)
                        }
                        color="primary"
                        sx={{
                          py: 2,
                          display: "flex",
                          justifyContent: "center",
                        }}
                        showFirstButton
                        showLastButton
                      />
                    </TableContainer>
                  </>
                )}

                {/* --------- ADD EXPENSE DIALOG --------- */}
                <Dialog
                  open={isAddExpenseDialogOpen}
                  onClose={handleCloseAddExpenseDialog}
                  aria-labelledby="add-expense-dialog-title"
                >
                  <DialogTitle id="add-expense-dialog-title">
                    Add New Expense
                  </DialogTitle>
                  <DialogContent>
                    <Box
                      display="flex"
                      flexDirection="column"
                      gap={2}
                      mt={1}
                      minWidth="300px"
                    >
                      <TextField
                        label="Type"
                        name="Type"
                        value={newExpense.Type}
                        onChange={handleAddExpenseInputChange}
                        variant="outlined"
                        required
                        error={Boolean(expenseErrors.Type)}
                        helperText={expenseErrors.Type}
                      />
                      <TextField
                        label="Price"
                        name="Price"
                        value={newExpense.Price}
                        onChange={handleAddExpenseInputChange}
                        variant="outlined"
                        required
                        error={Boolean(expenseErrors.Price)}
                        helperText={expenseErrors.Price}
                      />
                      <TextField
                        label="Currency"
                        name="Currency"
                        value={newExpense.Currency}
                        onChange={handleAddExpenseInputChange}
                        variant="outlined"
                        required
                        error={Boolean(expenseErrors.Currency)}
                        helperText={expenseErrors.Currency}
                      />
                    </Box>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={handleCloseAddExpenseDialog}
                      color="secondary"
                    >
                      Cancel
                    </Button>
                    <Button
                      onClick={handleAddExpenseSubmit}
                      color="primary"
                      variant="contained"
                      disabled={isLoading}
                    >
                      {isLoading ? <Spinner size="small" /> : "Add"}
                    </Button>
                  </DialogActions>
                </Dialog>

                {/* --------- EDIT EXPENSE DIALOG --------- */}
                <Dialog
                  open={isEditExpenseDialogOpen}
                  onClose={handleCloseEditExpenseDialog}
                  aria-labelledby="edit-expense-dialog-title"
                >
                  <DialogTitle id="edit-expense-dialog-title">
                    Edit Expense
                  </DialogTitle>
                  <DialogContent>
                    <Box
                      display="flex"
                      flexDirection="column"
                      gap={2}
                      mt={1}
                      minWidth="300px"
                    >
                      <TextField
                        label="Type"
                        name="Type"
                        value={editExpense.Type}
                        onChange={handleEditExpenseInputChange}
                        variant="outlined"
                        required
                        error={Boolean(expenseErrors.Type)}
                        helperText={expenseErrors.Type}
                      />
                      <TextField
                        label="Price"
                        name="Price"
                        value={editExpense.Price}
                        onChange={handleEditExpenseInputChange}
                        variant="outlined"
                        required
                        error={Boolean(expenseErrors.Price)}
                        helperText={expenseErrors.Price}
                      />
                      <TextField
                        label="Currency"
                        name="Currency"
                        value={editExpense.Currency}
                        onChange={handleEditExpenseInputChange}
                        variant="outlined"
                        required
                        error={Boolean(expenseErrors.Currency)}
                        helperText={expenseErrors.Currency}
                      />
                    </Box>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={handleCloseEditExpenseDialog}
                      color="secondary"
                    >
                      Cancel
                    </Button>
                    <Button
                      onClick={handleEditExpenseSubmit}
                      color="primary"
                      variant="contained"
                      disabled={isLoading}
                    >
                      {isLoading ? <Spinner size="small" /> : "Save"}
                    </Button>
                  </DialogActions>
                </Dialog>

                {/* --------- DELETE EXPENSE CONFIRMATION DIALOG --------- */}
                <Dialog
                  open={isDeleteDialogOpen}
                  onClose={handleCancelDeleteExpense}
                  aria-labelledby="delete-expense-dialog-title"
                >
                  <DialogTitle id="delete-expense-dialog-title">
                    Confirm Deletion
                  </DialogTitle>
                  <DialogContent>
                    Are you sure you want to delete the expense "
                    {expenseToDelete?.Type}"?
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={handleCancelDeleteExpense}
                      color="secondary"
                    >
                      Cancel
                    </Button>
                    <Button
                      onClick={handleConfirmDeleteExpense}
                      color="primary"
                      variant="contained"
                    >
                      Delete
                    </Button>
                  </DialogActions>
                </Dialog>
              </Box>
            )}
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
}

export default EditTruck;
