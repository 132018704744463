// EditOrder.js

import React, { useState, useEffect } from "react";
import {
  TextField,
  Checkbox,
  FormControlLabel,
  Button,
  Toolbar,
} from "@mui/material";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import Spinner from "./Spinner";
import SideNavBar from "./SideNavBar";
import { useNavigate } from "react-router-dom";
import "./Edit.css"; // Use the same CSS file as Edit.js

function EditOrder() {
  const [editData, setEditData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const loadNumber = sessionStorage.getItem("selectedLoadNumber");
    if (!loadNumber) {
      navigate("/order-history");
      return;
    }

    const fetchLoadData = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(
          `/api/get-load?loadNumber=${encodeURIComponent(loadNumber)}`
        );
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        // Assuming the backend response has a 'result' key
        setEditData(data.result);
      } catch (error) {
        console.error("Failed to fetch load data:", error);
        alert(`Error fetching load data: ${error.message}`);
        navigate("/order-history");
      } finally {
        setIsLoading(false);
      }
    };

    fetchLoadData();
  }, [navigate]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const dataToSend = { ...editData };
      delete dataToSend.Pdf;
      delete dataToSend.UserId;

      const response = await fetch("/api/update-load", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(dataToSend),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const updatedData = await response.json();
      console.log("Order updated:", updatedData);
      alert("Order updated successfully!");
      navigate("/order-history");
    } catch (error) {
      console.error("Failed to update order:", error);
      alert(`Error updating order: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading || !editData) {
    return (
      <div className="container">
        <SideNavBar />
        <div className="edit-window">
          <Toolbar style={{ minHeight: "83px" }} />
          <div className="edit-body">
            <div className="spinner-container">
              <Spinner />
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="container">
      <SideNavBar />
      <div className="edit-window">
        <Toolbar style={{ minHeight: "83px" }} />
        <div className="edit-body">
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <form onSubmit={handleSubmit} className="form-section">
              {/* Date Added */}
              <div className="form-group">
                <label htmlFor="DateAdded">Date Added</label>
                <DatePicker
                  label="Date Added"
                  value={editData.DateAdded || null}
                  onChange={(newValue) => {
                    setEditData((prevData) => ({
                      ...prevData,
                      DateAdded: newValue,
                    }));
                  }}
                  components={{ OpenPickerIcon: () => null }} // Hide the calendar icon
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      fullWidth
                      required
                      sx={{
                        "& .MuiInputBase-input": {
                          color: "white",
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#3c94fc",
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#3c94fc",
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#3c94fc",
                        },
                        backgroundColor: "#1e1e1e",
                        borderRadius: "4px",
                      }}
                      InputLabelProps={{
                        style: { color: "white" },
                      }}
                      // Remove the calendar icon by hiding it via components prop
                      // Do not set endAdornment to null here
                    />
                  )}
                  sx={{
                    width: "100%",
                    "& .MuiSvgIcon-root": {
                      color: "white",
                    },
                  }}
                />
              </div>

              {/* Customer Order Number (Previously Load#) */}
              <div className="form-group">
                <label htmlFor="CustomerOrderNumber">
                  Customer Order Number
                </label>
                <TextField
                  name="CustomerOrderNumber"
                  value={editData.CustomerOrderNumber || ""}
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  required
                />
              </div>

              {/* Order Number */}
              <div className="form-group">
                <label htmlFor="OrderNumber">Order Number</label>
                <TextField
                  name="OrderNumber"
                  value={editData.OrderNumber || ""}
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  required
                />
              </div>

              {/* Pickup Date */}
              <div className="form-group">
                <label htmlFor="PickupDate">Pickup Date</label>
                <DatePicker
                  label="Pickup Date"
                  value={editData.PickupDate || null}
                  onChange={(newValue) => {
                    setEditData((prevData) => ({
                      ...prevData,
                      PickupDate: newValue,
                    }));
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      fullWidth
                      required
                      sx={{
                        "& .MuiInputBase-input": {
                          color: "white",
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#3c94fc",
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#3c94fc",
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#3c94fc",
                        },
                        backgroundColor: "#1e1e1e",
                        borderRadius: "4px",
                      }}
                      InputLabelProps={{
                        style: { color: "white" },
                      }}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: null, // Remove the calendar icon
                      }}
                    />
                  )}
                  sx={{
                    width: "100%",
                    "& .MuiSvgIcon-root": {
                      color: "white",
                    },
                  }}
                />
              </div>

              {/* Pickup Location */}
              <div className="form-group">
                <label htmlFor="PickupLocation">Pickup Location</label>
                <TextField
                  name="PickupLocation"
                  value={editData.PickupLocation || ""}
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  required
                />
              </div>

              {/* Delivery Location */}
              <div className="form-group">
                <label htmlFor="DeliveryDate">Delivery Date</label>
                <DatePicker
                  label="Delivery Date"
                  value={editData.DeliveryDate || null}
                  onChange={(newValue) => {
                    setEditData((prevData) => ({
                      ...prevData,
                      DeliveryDate: newValue,
                    }));
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      fullWidth
                      required
                      sx={{
                        "& .MuiInputBase-input": {
                          color: "white",
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#3c94fc",
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#3c94fc",
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#3c94fc",
                        },
                        backgroundColor: "#1e1e1e",
                        borderRadius: "4px",
                      }}
                      InputLabelProps={{
                        style: { color: "white" },
                      }}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: null, // Remove the calendar icon
                      }}
                    />
                  )}
                  sx={{
                    width: "100%",
                    "& .MuiSvgIcon-root": {
                      color: "white",
                    },
                  }}
                />
              </div>

              {/* Price */}
              <div className="form-group">
                <label htmlFor="Price">Price</label>
                <TextField
                  name="Price"
                  value={editData.Price || ""}
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  required
                />
              </div>

              {/* Price Kept */}
              <div className="form-group">
                <label htmlFor="PriceKept">Price Kept</label>
                <TextField
                  name="PriceKept"
                  value={editData.PriceKept || ""}
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  required
                />
              </div>

              {/* Trip Type */}
              <div className="form-group">
                <label htmlFor="TripType">Trip Type</label>
                <TextField
                  name="TripType"
                  value={editData.TripType || ""}
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  required
                />
              </div>

              {/* Carrier Or Equipment */}
              <div className="form-group">
                <label htmlFor="CarrierOrEquipment">Carrier or Equipment</label>
                <TextField
                  name="CarrierOrEquipment"
                  value={editData.CarrierOrEquipment || ""}
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  required
                />
              </div>

              {/* Status */}
              <div className="form-group">
                <label htmlFor="Status">Status</label>
                <TextField
                  name="Status"
                  value={editData.Status || ""}
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  required
                />
              </div>

              {/* Currency */}
              <div className="form-group">
                <label htmlFor="Currency">Currency</label>
                <TextField
                  name="Currency"
                  value={editData.Currency || ""}
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  required
                />
              </div>

              {/* Company */}
              <div className="form-group">
                <label htmlFor="Company">Company</label>
                <TextField
                  name="Company"
                  value={editData.Company || ""}
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  required
                />
              </div>

              {/* Driver in Rush Checkbox */}
              <FormControlLabel
                control={
                  <Checkbox
                    checked={editData.inRush || false}
                    onChange={(e) =>
                      setEditData((prevData) => ({
                        ...prevData,
                        inRush: e.target.checked,
                      }))
                    }
                    name="inRush"
                    color="primary"
                    sx={{ color: "white" }}
                  />
                }
                label="Driver in Rush"
              />

              {/* Submit and Cancel Buttons */}
              <div className="button-group">
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={isLoading}
                >
                  {isLoading ? <Spinner /> : "Update Order"}
                </Button>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() => navigate("/order-history")}
                  disabled={isLoading}
                >
                  Cancel
                </Button>
              </div>
            </form>
          </LocalizationProvider>
        </div>
      </div>
    </div>
  );
}

export default EditOrder;
