// Orders.js
import React, { useState, useEffect, useRef } from "react";
import SideNavBar from "./SideNavBar";
import LoadingIndicator from "./LoadingIndicator";
import ConfirmationModal from "./ConfirmationModal";
import OrderDetails from "./OrderDetails";
import "./Chat.css";
import { Toolbar } from "@mui/material";

function Orders() {
  const MAX_MESSAGES = 30;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [chatHistory, setChatHistory] = useState([]);

  const [input, setInput] = useState("");
  const [orderNumber, setOrderNumber] = useState("");
  const [pickupDate, setPickupDate] = useState("");
  const [pickupLocation, setPickupLocation] = useState("");
  const [deliveryLocation, setDeliveryLocation] = useState("");
  const [deliveryDate, setDeliveryDate] = useState("");
  const [rate, setRate] = useState(0.00);
  const [currency, setCurrency] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [priceGiven, setPriceGiven] = useState(0.00);
  const [tripType, setTripType] = useState("");
  const [equipment, setEquipment] = useState("");
  const [status, setStatus] = useState("");
  const [pdfBlob, setPdfBlob] = useState(null);
  const chatBodyRef = useRef(null);
  const inputRef = useRef(null);
  const [storageUserId, setStorageUserId] = useState("");

  // Ref for the hidden file input
  const fileInputRef = useRef(null);

  useEffect(() => {
    let sessionId = sessionStorage.getItem("session_id");
    if (!sessionId) {
      sessionId = generateSessionId();
      sessionStorage.setItem("session_id", sessionId);
    }
  }, []);

  useEffect(() => {
    const storedUserId = sessionStorage.getItem("storageUserId");
    if (storedUserId) {
      setStorageUserId(storedUserId);
      console.log("User ID:", storedUserId);
    }
  }, []);

  useEffect(() => {
    // Scroll to bottom when new messages are added
    if (chatBodyRef.current) {
      chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight;
    }
  }, [chatHistory, isLoading]);

  const addSystemMessage = (content) => {
    const newMessage = {
      id: generateUniqueId(),
      ...content, // Spread the content object
      isUser: false,
    };
    setChatHistory((prevHistory) =>
      [...prevHistory, newMessage].slice(-MAX_MESSAGES)
    );
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const confirmNewChat = async () => {
    // Reset order details state variables
    setOrderNumber("");
    setPickupDate("");
    setPickupLocation("");
    setDeliveryLocation("");
    setDeliveryDate("");
    setRate(0.00);
    setCurrency("");
    setCompanyName("");
    setPriceGiven(0.00);
    setTripType("");
    setEquipment("");
    setStatus("");
    setPdfBlob(null);

    await handleRequestCompleted(false);
    setChatHistory([]);
    addSystemMessage({ text: "How can I assist you with Orders Addition?" });
    closeModal();
  };

  const handleRequestCompleted = async (displayMessage = true) => {
    const textToSend = "This part is completed";
    setIsLoading(true);

    if (displayMessage) {
      const userMessage = {
        id: generateUniqueId(),
        text: textToSend,
        isUser: true,
      };
      setChatHistory((prevHistory) =>
        [...prevHistory, userMessage].slice(-MAX_MESSAGES)
      );
    }

    try {
      const response = await callAzureFunction(textToSend);
      const answer = response.message;

      if (typeof answer === "object" && answer !== null) {
        // Handle object response if needed
      } else {
        const aiMessage = {
          id: generateUniqueId(),
          text: answer,
          isUser: false,
        };
        setChatHistory((prevHistory) =>
          [...prevHistory, aiMessage].slice(-MAX_MESSAGES)
        );
      }
    } catch (err) {
      console.error("Error fetching response from the API:", err);
      const errorMessage = {
        id: generateUniqueId(),
        text: "Error fetching response from the system.",
        isUser: false,
      };
      setChatHistory((prevHistory) =>
        [...prevHistory, errorMessage].slice(-MAX_MESSAGES)
      );
    } finally {
      setIsLoading(false);
    }
  };

  const blobToBase64 = (blob) => {
    const reader = new FileReader();
    return new Promise((resolve) => {
      reader.onloadend = () => {
        resolve(reader.result);
      };
      reader.readAsDataURL(blob);
    });
  };

  const uploadToBlobStorage = async (pdfBlob, fileName) => {
    try {
      const base64Content = await blobToBase64(pdfBlob);
      const base64Data = base64Content.replace(/^data:.+;base64,/, "");

      const body = JSON.stringify({
        blobName: fileName,
        blobContent: base64Data,
      });

      const response = await fetch("/api/save-to-blob", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: body,
      });

      const data = await response.json();
      if (response.ok) {
        console.log("PDF uploaded successfully: ", data.url);
        return data.url; // URL of the uploaded PDF in blob storage
      } else {
        throw new Error(data.error || "Failed to upload PDF");
      }
    } catch (error) {
      console.log("Error uploading PDF to blob storage: ", error);
      return null;
    }
  };

  const handleSend = async () => {
    const textToSend = input.trim();
    if (textToSend === "") return;

    const userMessage = {
      id: generateUniqueId(),
      text: textToSend,
      isUser: true,
    };
    setChatHistory((prevHistory) =>
      [...prevHistory, userMessage].slice(-MAX_MESSAGES)
    );

    setInput("");
    setIsLoading(true);

    try {
      const response = await callAzureFunction(textToSend);
      console.log("Response from API:", response);

      let answer = response.message;
      let parsedAnswer = answer;

      if (typeof answer === "string") {
        try {
          parsedAnswer = JSON.parse(answer);
        } catch (e) {
          console.error("Failed to parse answer as JSON:", e);
        }
      }

      if (typeof parsedAnswer === "object" && parsedAnswer !== null) {
        setOrderNumber(parsedAnswer.order_number || "");
        console.log("Order Number:", parsedAnswer.order_number);
        setPickupDate(parsedAnswer.pickup_date || "");
        setPickupLocation(parsedAnswer.pickup_location || "");
        setDeliveryLocation(parsedAnswer.delivery_location || "");
        setDeliveryDate(parsedAnswer.delivery_date || "");
        setRate(parsedAnswer.rate || 0.00);
        setCurrency(parsedAnswer.currency || "");
        setCompanyName(parsedAnswer.company_name || "");
        setEquipment(parsedAnswer.equipment || "");
        setTripType(parsedAnswer.trip_type || "");
        setStatus(parsedAnswer.status || "");
        setPriceGiven(parsedAnswer.price_given || 0.00);

        console.log("Order details set:", parsedAnswer);
      }
    } catch (err) {
      console.error("Error fetching response from the API:", err);
      const errorMessage = {
        id: generateUniqueId(),
        text: "Error fetching response from the system.",
        isUser: false,
      };
      setChatHistory((prevHistory) =>
        [...prevHistory, errorMessage].slice(-MAX_MESSAGES)
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      // Clear chat history and reset order details when a new PDF is uploaded
      setChatHistory([]);
      setOrderNumber("");
      setPickupDate("");
      setPdfBlob(null);
      setPickupLocation("");
      setDeliveryLocation("");
      setDeliveryDate("");
      setRate(0.00);
      setCurrency("");
      setCompanyName("");
      setEquipment("");
      setTripType("");
      setStatus("");
      setPriceGiven(0.00);

      // Validate that the file is a PDF
      if (file.type !== "application/pdf") {
        alert("Please upload a PDF file.");
        return;
      }

      // Create a Blob from the file
      const pdfBlob = new Blob([file], { type: "application/pdf" });
      setPdfBlob(pdfBlob);

      // Create a FormData object and append the PDF Blob
      const formData = new FormData();
      formData.append("pdf", pdfBlob);
      formData.append("session_id", sessionStorage.getItem("session_id")); // Include session_id if needed

      // Add the PDF to the chat history as a user message
      const reader = new FileReader();
      reader.onload = () => {
        const fileDataUrl = reader.result;

        const pdfMessage = {
          id: generateUniqueId(),
          fileName: file.name,
          fileUrl: fileDataUrl, // For download link
          isUser: true,
          type: "pdf", // Custom type to identify PDF messages
        };

        setChatHistory((prevHistory) =>
          [...prevHistory, pdfMessage].slice(-MAX_MESSAGES)
        );
      };
      reader.readAsDataURL(file);

      // Send the PDF to the backend
      setIsLoading(true); // Show loading indicator
      try {
        const response = await fetch("/api/load-info-pdf", {
          method: "POST",
          body: formData, // Automatically sets the correct headers for multipart/form-data
        });

        if (!response.ok) {
          throw new Error(`Server responded with ${response.status}`);
        }

        const data = await response.json();

        if (data.message) {
          if (typeof data.message === "object" && data.message !== null) {
            const answer = data.message;
            // Set individual state variables with API response data
            setOrderNumber(answer.order_number || "");
            setPickupDate(answer.pickup_date || "");
            setPickupLocation(answer.pickup_location || "");
            setDeliveryLocation(answer.delivery_location || "");
            setDeliveryDate(answer.delivery_date || "");
            setRate(answer.rate || 0.00);
            setCurrency(answer.currency || "");
            setCompanyName(answer.company_name || "");
          } else {
            addSystemMessage({ text: `Backend Response: ${data.message}` });
          }
        } else {
          addSystemMessage({ text: "PDF processed successfully." });
        }
      } catch (error) {
        console.error("Error uploading PDF:", error);
        addSystemMessage({ text: `Error uploading PDF: ${error.message}` });
      } finally {
        setIsLoading(false);
        event.target.value = "";
      }
    }
  };

  const callAzureFunction = async (inputText) => {
    const apiUrl = "/api/load-info-text"; // Update this if needed

    const response = await fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        question: inputText,
        session_id: sessionStorage.getItem("session_id"),
      }),
    });

    if (!response.ok) {
      throw new Error("API request failed");
    }

    return await response.json();
  };

  const adjustInputHeight = () => {
    if (inputRef.current) {
      inputRef.current.style.height = "auto";
      inputRef.current.style.height = `${Math.min(
        inputRef.current.scrollHeight,
        150
      )}px`;
    }
  };

  useEffect(() => {
    adjustInputHeight();
  }, [input]);

  const generateUniqueId = () => {
    return `${Date.now()}-${Math.random().toString(36).substr(2, 9)}`;
  };

  const saveOrderDetails = async (editedDetails) => {
    try {
      // Upload the PDF to blob storage and get the URL
      const blobUrl = await uploadToBlobStorage(
        pdfBlob,
        `${orderNumber}-${companyName}.pdf`
      );

      // Add the blobUrl to the editedDetails object
      const detailsWithBlobUrl = {
        ...editedDetails,
        blobUrl, // Add the blobUrl to the object
      };

      // Send the updated details to the backend
      const response = await fetch("/api/add-load", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(detailsWithBlobUrl),
      });

      if (!response.ok) {
        throw new Error("Failed to save order details");
      }

      const data = await response.json();
      console.log("Order details saved:", data);
      alert("Order details saved successfully.");

      // Reset the form fields after saving
      setOrderNumber("");
      setPickupDate("");
      setPdfBlob(null);
      setPickupLocation("");
      setDeliveryLocation("");
      setDeliveryDate("");
      setRate(0.00);
      setCurrency("");
      setCompanyName("");
      setPriceGiven(0.00);
      setTripType("");
      setEquipment("");
      setStatus("");
      setChatHistory([]);
    } catch (error) {
      console.error("Error saving order details:", error);
      alert("Error saving order details.");
    }
  };

  const generateSessionId = () => {
    return crypto.randomUUID();
  };

  // Handler for 📌 button click to trigger file input
  const handleFileButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <div className="chat-container">
      <SideNavBar handleNewChat={openModal} />
      <ConfirmationModal
        open={isModalOpen}
        handleClose={closeModal}
        handleConfirm={confirmNewChat}
        option="Email Generation"
      />
      <div className="chat-window">
        <Toolbar sx={{ minHeight: "84px" }} />
        <div className="chat-body" ref={chatBodyRef}>
          {chatHistory.map((message) => (
            <div
              key={message.id}
              className={`chat-message ${
                message.isUser ? "user-message" : "system-message"
              }`}
            >
              {message.type === "pdf" ? (
                <div className="pdf-message">
                  📄 <strong>{message.fileName}</strong>
                  {message.fileUrl && (
                    <a
                      href={message.fileUrl}
                      download={message.fileName}
                      className="download-link"
                      style={{
                        marginTop: "10px",
                        color: "white",
                        display: "block",
                      }}
                    >
                      📥 Download
                    </a>
                  )}
                </div>
              ) : (
                message.text
              )}
            </div>
          ))}
          {isLoading && <LoadingIndicator />}

          {/* Render OrderDetails component if orderNumber is set */}
          {orderNumber && (
            <OrderDetails
              orderNumber={orderNumber}
              setOrderNumber={setOrderNumber}
              pickupDate={pickupDate}
              setPickupDate={setPickupDate}
              pickupLocation={pickupLocation}
              setPickupLocation={setPickupLocation}
              deliveryLocation={deliveryLocation}
              setDeliveryLocation={setDeliveryLocation}
              deliveryDate={deliveryDate}
              setDeliveryDate={setDeliveryDate}
              rate={rate}
              setRate={setRate}
              currency={currency}
              setCurrency={setCurrency}
              companyName={companyName}
              setCompanyName={setCompanyName}
              priceGiven={priceGiven}
              setPriceGiven={setPriceGiven}
              tripType={tripType}
              setTripType={setTripType}
              equipment={equipment}
              setEquipment={setEquipment}
              status={status}
              setStatus={setStatus}
              userId={storageUserId}
              onSave={saveOrderDetails}
            />
          )}
        </div>
        <div className="chat-footer">
          <button
            style={{ border: "none", marginRight: "10px" }}
            className="file-upload-btn"
            title="Upload Files"
            onClick={handleFileButtonClick}
          >
            📌
          </button>
          {/* Hidden file input */}
          <input
            type="file"
            accept="application/pdf"
            style={{ display: "none" }}
            ref={fileInputRef}
            onChange={handleFileChange}
          />
          <textarea
            ref={inputRef}
            value={input}
            onChange={(e) => {
              setInput(e.target.value);
              adjustInputHeight();
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter" && !e.shiftKey) {
                e.preventDefault();
                handleSend();
              }
            }}
            placeholder="Type a message..."
            className="input-field"
          />
          <button
            className="send-btn"
            title="Send Message"
            onClick={handleSend}
            aria-label="Send Message"
            disabled={isLoading}
          >
            ➤
          </button>
        </div>
      </div>
    </div>
  );
}

export default Orders;
