import React, { useState, useEffect } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Toolbar } from "@mui/material";
import Spinner from "./Spinner";
import CloseIcon from "@mui/icons-material/Close";
import {
  Card,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Box,
  IconButton,
  Typography,
  Pagination,
  Paper,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import SideNavBar from "./SideNavBar";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import "./Broker.css";

function ParsManagement() {
  const [parsInfo, setParsInfo] = useState([]);
  const [newParsNumber, setNewParsNumber] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [userId, setUserId] = useState("");
  const itemsPerPage = 10;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  const navigate = useNavigate();

  const handleEditPars = (parsData) => {
    sessionStorage.setItem("parsData", parsData);
    console.log("PARS Data:", parsData);
    navigate(`/edit`);
  };

  useEffect(() => {
    const fetchData = async () => {
      const storedUserId = sessionStorage.getItem("storageUserId");

      if (storedUserId) setUserId(storedUserId);

      setIsLoading(true);
      try {
        const response = await fetch(
          `/api/fetch-pars?userId=${encodeURIComponent(storedUserId)}`,
          {
            method: "GET",
            headers: { "Content-Type": "application/json" },
          }
        );
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        setParsInfo(data.result);
      } catch (error) {
        console.error("Failed to fetch PARS information:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const fetchParsInfo = async () => {
    setIsLoading(true);
    try {
      let storedUserId = sessionStorage.getItem("storageUserId");
      const response = await fetch(
        `/api/fetch-pars?userId=${encodeURIComponent(storedUserId)}`,
        {
          method: "GET",
          headers: { "Content-Type": "application/json" },
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      setParsInfo(data.result);
    } catch (error) {
      console.log(error.message);
    }
    setIsLoading(false);
  };

  const handleAddParsNumber = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    const numbers = newParsNumber.split(",").map((num) => num.trim());
    console.log("Adding PARS numbers:", numbers);
    const endpoint = `/api/insert-pars`;

    for (const number of numbers) {
      const data = { number, userId };
      try {
        const response = await fetch(endpoint, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(data),
        });
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        await response.json();
      } catch (error) {
        console.error("Error inserting PARS number:", error);
      }
    }
    setNewParsNumber("");
    await fetchParsInfo();
    setIsLoading(false);
  };

  const handleDeletePars = async (number) => {
    const confirmDelete = window.confirm(
      `Are you sure you want to delete PARS number: ${number}?`
    );
    if (!confirmDelete) return;

    setIsLoading(true);
    try {
      const response = await fetch(`/api/delete-pars`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ number }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      alert("PARS number deleted successfully!");
      setParsInfo(parsInfo.filter((item) => item.Number !== number));
    } catch (error) {
      console.error("Failed to delete PARS number:", error);
      alert(`Error deleting PARS number: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  const filteredItems = parsInfo.filter((item) => {
    const searchLower = searchTerm.toLowerCase();
    return (
      item.Number?.toLowerCase().includes(searchLower) ||
      item.Border?.toLowerCase().includes(searchLower) ||
      item.Broker?.toLowerCase().includes(searchLower) ||
      item.Email?.toLowerCase().includes(searchLower) ||
      item.Phone?.toLowerCase().includes(searchLower) ||
      item.Status?.toLowerCase().includes(searchLower) ||
      item.LoadCompany?.toLowerCase().includes(searchLower) ||
      item.Date?.toLowerCase().includes(searchLower)
    );
  });

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredItems.slice(indexOfFirstItem, indexOfLastItem);

  const darkTheme = createTheme({
    palette: {
      mode: "dark",
      background: {
        default: "#000000",
        paper: "#000000",
      },
      text: {
        primary: "#ffffff",
        secondary: "#aaaaaa",
      },
      primary: {
        main: "#3c94fc",
      },
      secondary: {
        main: "#f50057",
      },
    },
    components: {
      MuiPagination: {
        styleOverrides: {
          root: {
            backgroundColor: "#000000",
            padding: "16px 0",
          },
          ul: {
            justifyContent: "center",
          },
        },
      },
      MuiPaginationItem: {
        styleOverrides: {
          root: {
            color: "#fff",
            backgroundColor: "#000000",
            "&.Mui-selected": {
              backgroundColor: "#3c94fc",
              color: "#fff",
            },
            "&:hover": {
              backgroundColor: "#111111",
            },
          },
        },
      },
    },
  });

  return (
    <ThemeProvider theme={darkTheme}>
      <div className="chat-container">
        <SideNavBar />
        <div className="broker-window">
          <Toolbar style={{ minHeight: "83px" }} />
          <div className="broker-body">
            <Card sx={{ maxWidth: "100%", overflowX: "auto", m: 2 }}>
              <Typography
                variant="h4"
                component="div"
                sx={{
                  m: 2,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                PARS Management
              </Typography>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                width="100%"
              >
                <TextField
                  variant="outlined"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  placeholder="Search by any column"
                  margin="normal"
                  sx={{
                    m: 2,
                    width: "50%",
                    input: { color: "#fff" },
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "#555",
                      },
                      "&:hover fieldset": {
                        borderColor: "#777",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#3c94fc",
                      },
                    },
                  }}
                  InputLabelProps={{
                    style: { color: "#fff" },
                  }}
                />
                <Button
                  onClick={openModal}
                  variant="contained"
                  color="primary"
                  class="add-button"
                  sx={{ m: 2 }}
                >
                  Add PARS Numbers
                </Button>
              </Box>

              {isLoading ? (
                <Box
                  sx={{
                    m: 2,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  <Spinner sx={{ color: "#3c94fc" }} />
                </Box>
              ) : (
                <>
                  <TableContainer component={Paper}>
                    <Table stickyHeader aria-label="pars table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Number</TableCell>
                          <TableCell>Border</TableCell>
                          <TableCell>Broker</TableCell>
                          <TableCell>Email</TableCell>
                          <TableCell>Phone</TableCell>
                          <TableCell>Status</TableCell>
                          <TableCell>Company</TableCell>
                          <TableCell>Date</TableCell>
                          <TableCell>Action</TableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {currentItems.map((item, index) => (
                          <TableRow key={index}>
                            <TableCell>{item.Number}</TableCell>
                            <TableCell>{item.Border}</TableCell>
                            <TableCell>{item.CustomsBroker}</TableCell>
                            <TableCell>{item.BrokerEmail}</TableCell>
                            <TableCell>{item.BrokerPhone}</TableCell>
                            <TableCell>{item.Status}</TableCell>
                            <TableCell>{item.LoadCompany}</TableCell>
                            <TableCell>{item.Date}</TableCell>
                            <TableCell>
                              <IconButton
                                onClick={() => handleEditPars(item.Number)}
                                sx={{ color: "#fff" }}
                              >
                                <EditIcon />
                              </IconButton>
                              <IconButton
                                onClick={() => handleDeletePars(item.Number)}
                                sx={{ color: "#fff" }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                    <Pagination
                      count={Math.ceil(filteredItems.length / itemsPerPage)}
                      page={currentPage}
                      onChange={(event, value) => setCurrentPage(value)}
                      color="primary"
                      sx={{
                        py: 2,
                        display: "flex",
                        justifyContent: "center",
                      }}
                      showFirstButton
                      showLastButton
                    />
                  </TableContainer>
                  <Dialog
                    className="add-paps-dialog"
                    open={isModalOpen}
                    onClose={closeModal}
                    fullWidth
                  >
                    <DialogTitle>Add PAPS Numbers</DialogTitle>
                    <DialogContent>
                      <Typography gutterBottom>
                        Enter PARS numbers (comma-separated)
                      </Typography>
                      <textarea
                        value={newParsNumber}
                        className="paps-input"
                        onChange={(e) => setNewParsNumber(e.target.value)}
                        style={{
                          width: "100%",
                          height: "150px",
                          padding: "10px",
                          fontSize: "16px",
                          border: "1px solid #3c94fc",
                          borderRadius: "4px",
                          fontFamily:
                            "'Roboto', 'Helvetica', 'Arial', sans-serif",
                          resize: "vertical", // Allows resizing vertically
                        }}
                      />
                      <DialogActions>
                        <Button onClick={handleAddParsNumber} color="primary">
                          Add
                        </Button>
                      </DialogActions>
                    </DialogContent>
                    <DialogActions>
                      <IconButton
                        onClick={closeModal}
                        aria-label="close"
                        style={{
                          position: "absolute",
                          top: "10px",
                          right: "10px",
                          zIndex: 9000,
                          border: "2px solid red",
                        }}
                      >
                        <CloseIcon style={{ color: "red" }} />
                      </IconButton>
                    </DialogActions>
                  </Dialog>
                </>
              )}
            </Card>
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
}

export default ParsManagement;
