// MessageModal.js
import React from "react";
import PropTypes from "prop-types";
import { Typography, Button } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import "./MessageModal.css"; // Ensure this path is correct based on your project structure

function MessageModal({ open, handleClose, title, message, status }) {
  if (!open) return null; // Do not render if not open

  // Determine the icon and its color based on the status
  let StatusIcon = null;
  let iconColor = "";
  if (status === "Good") {
    StatusIcon = CheckCircleIcon;
    iconColor = "#4caf50"; // Green color for success
  } else if (status === "Bad") {
    StatusIcon = ErrorIcon;
    iconColor = "#f44336"; // Red color for error
  }

  return (
    <div
      className="message-modal-overlay"
      role="dialog"
      aria-labelledby="message-modal-title"
      aria-describedby="message-modal-description"
    >
      <div className="message-modal-box">
        {/* Header */}
        <div className="message-modal-header">
          {status === "Waiting" && <div className="pulsing-circle"></div>}
          {/* Use StatusIcon if available */}
          {StatusIcon && (
            <StatusIcon className="status-icon" style={{ color: iconColor }} />
          )}
          <span className="modal-title">
            {title || (status === "Waiting" ? "Processing" : "")}
          </span>
        </div>

        {/* Content */}
        <div className="message-modal-content" id="message-modal-description">
          <Typography variant="body1">{message || "Please wait..."}</Typography>
        </div>

        {/* Actions - Only for "Good" and "Bad" statuses */}
        {(status === "Good" || status === "Bad") && (
          <div className="message-modal-actions">
            <Button className="message-modal-button" onClick={handleClose}>
              Close
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}

// Define PropTypes for type checking
MessageModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func, // Optional for "Waiting" since there's no button
  title: PropTypes.string,
  message: PropTypes.string,
  status: PropTypes.oneOf(["Good", "Bad", "Waiting"]).isRequired,
};

// Default Props
MessageModal.defaultProps = {
  handleClose: () => {},
  title: "Processing",
  message: "Please wait...",
};

export default MessageModal;
