// OrderDetails.js
import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Typography,
  TextField,
  Button,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from "@mui/material";

function OrderDetails({
  orderNumber,
  setOrderNumber,
  pickupDate,
  setPickupDate,
  pickupLocation,
  setPickupLocation,
  deliveryLocation,
  setDeliveryLocation,
  deliveryDate,
  setDeliveryDate,
  rate,
  setRate,
  currency,
  setCurrency,
  companyName,
  setCompanyName,
  priceGiven,
  setPriceGiven,
  tripType,
  setTripType,
  equipment,
  setEquipment,
  status,
  setStatus,
  userId,
  onSave,
}) {
  // State to hold the list of trucks
  const [trucks, setTrucks] = useState([]);
  const [isTrucksLoading, setIsTrucksLoading] = useState(false);
  const [trucksError, setTrucksError] = useState("");

  // Fetch trucks from the backend when the component mounts
  useEffect(() => {
    const fetchTrucks = async () => {
      const storedUserId = sessionStorage.getItem("storageUserId");

      setIsTrucksLoading(true);
      try {
        const response = await fetch(
          `/api/fetch-trucks?userId=${encodeURIComponent(storedUserId)}`,
          {
            method: "GET",
            headers: { "Content-Type": "application/json" },
          }
        );
        if (!response.ok) {
          throw new Error(`Error fetching trucks: ${response.statusText}`);
        }
        const data = await response.json();
        setTrucks(data.result || []);
      } catch (error) {
        console.error("Failed to fetch trucks:", error);
        setTrucksError("Failed to load trucks.");
      } finally {
        setIsTrucksLoading(false);
      }
    };

    fetchTrucks();
  }, []);

  const handleSave = () => {
    const editedDetails = {
      order_number: orderNumber,
      pickup_date: pickupDate,
      pickup_location: pickupLocation,
      delivery_location: deliveryLocation,
      delivery_date: deliveryDate,
      rate: rate,
      currency: currency,
      company_name: companyName,
      price_given: priceGiven,
      trip_type: tripType,
      equipment: equipment,
      status: status,
      userId: userId,
    };
    onSave(editedDetails);
  };

  const cellStyle = {
    color: "white",
  };

  const headerStyle = {
    fontWeight: "bold",
    color: "white",
    width: "40%",
  };

  useEffect(() => {
    console.log("OrderDetails props:", {
      orderNumber,
      pickupDate,
      pickupLocation,
      deliveryLocation,
      deliveryDate,
      rate,
      currency,
      companyName,
      priceGiven,
      tripType,
      equipment,
      status,
      userId,
    });
  }, [
    orderNumber,
    pickupDate,
    pickupLocation,
    deliveryLocation,
    deliveryDate,
    rate,
    currency,
    companyName,
    priceGiven,
    tripType,
    equipment,
    status,
    userId,
  ]);

  return (
    <TableContainer
      component={Paper}
      style={{ marginTop: "20px", backgroundColor: "black" }}
    >
      <Typography
        variant="h6"
        align="center"
        gutterBottom
        style={{ color: "white", padding: "10px 0" }}
      >
        Order Details
      </Typography>
      <Table>
        <TableBody>
          {/* Order Number */}
          <TableRow>
            <TableCell style={headerStyle}>Order Number</TableCell>
            <TableCell style={cellStyle}>
              <TextField
                value={orderNumber}
                onChange={(e) => setOrderNumber(e.target.value)}
                InputProps={{
                  style: { color: "white" },
                }}
                variant="standard"
              />
            </TableCell>
          </TableRow>
          {/* Pickup Date */}
          <TableRow>
            <TableCell style={headerStyle}>Pickup Date</TableCell>
            <TableCell style={cellStyle}>
              <TextField
                type="date"
                value={pickupDate}
                onChange={(e) => setPickupDate(e.target.value)}
                InputProps={{
                  style: { color: "white" },
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="standard"
              />
            </TableCell>
          </TableRow>
          {/* Pickup Location */}
          <TableRow>
            <TableCell style={headerStyle}>Pickup Location</TableCell>
            <TableCell style={cellStyle}>
              <TextField
                value={pickupLocation}
                onChange={(e) => setPickupLocation(e.target.value)}
                InputProps={{
                  style: { color: "white" },
                }}
                variant="standard"
              />
            </TableCell>
          </TableRow>
          {/* Delivery Location */}
          <TableRow>
            <TableCell style={headerStyle}>Delivery Location</TableCell>
            <TableCell style={cellStyle}>
              <TextField
                value={deliveryLocation}
                onChange={(e) => setDeliveryLocation(e.target.value)}
                InputProps={{
                  style: { color: "white" },
                }}
                variant="standard"
              />
            </TableCell>
          </TableRow>
          {/* Delivery Date */}
          <TableRow>
            <TableCell style={headerStyle}>Delivery Date</TableCell>
            <TableCell style={cellStyle}>
              <TextField
                type="date"
                value={deliveryDate}
                onChange={(e) => setDeliveryDate(e.target.value)}
                InputProps={{
                  style: { color: "white" },
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="standard"
              />
            </TableCell>
          </TableRow>
          {/* Rate */}
          <TableRow>
            <TableCell style={headerStyle}>Rate</TableCell>
            <TableCell style={cellStyle}>
              <TextField
                value={rate}
                onChange={(e) => setRate(e.target.value)}
                InputProps={{
                  style: { color: "white" },
                }}
                variant="standard"
              />
            </TableCell>
          </TableRow>
          {/* Currency */}
          <TableRow>
            <TableCell style={headerStyle}>Currency</TableCell>
            <TableCell style={cellStyle}>
              <TextField
                value={currency}
                onChange={(e) => setCurrency(e.target.value)}
                InputProps={{
                  style: { color: "white" },
                }}
                variant="standard"
              />
            </TableCell>
          </TableRow>
          {/* Company Name */}
          <TableRow>
            <TableCell style={headerStyle}>Company Name</TableCell>
            <TableCell style={cellStyle}>
              <TextField
                value={companyName}
                onChange={(e) => setCompanyName(e.target.value)}
                InputProps={{
                  style: { color: "white" },
                }}
                variant="standard"
              />
            </TableCell>
          </TableRow>
          {/* Price Given */}
          <TableRow>
            <TableCell style={headerStyle}>Price Given</TableCell>
            <TableCell style={cellStyle}>
              <TextField
                value={priceGiven}
                onChange={(e) => setPriceGiven(e.target.value)}
                InputProps={{
                  style: { color: "white" },
                }}
                variant="standard"
              />
            </TableCell>
          </TableRow>
          {/* Trip Type with Dropdown */}
          <TableRow>
            <TableCell style={headerStyle}>Trip Type</TableCell>
            <TableCell style={cellStyle}>
              <TextField
                select
                value={tripType}
                onChange={(e) => setTripType(e.target.value)}
                InputProps={{
                  style: { color: "white" },
                }}
                variant="standard"
                fullWidth
              >
                <MenuItem value="Regular">Regular</MenuItem>
                <MenuItem value="Outsource">Outsource</MenuItem>
              </TextField>
            </TableCell>
          </TableRow>
          {/* Equipment */}
          <TableRow>
            <TableCell style={headerStyle}>Equipment</TableCell>
            <TableCell style={cellStyle}>
              {tripType === "Regular" ? (
                isTrucksLoading ? (
                  <Typography style={{ color: "white" }}>Loading...</Typography>
                ) : trucksError ? (
                  <Typography style={{ color: "red" }}>
                    {trucksError}
                  </Typography>
                ) : (
                  <FormControl variant="standard" fullWidth>
                    <InputLabel
                      id="equipment-select-label"
                      style={{ color: "white" }}
                    >
                      Select Truck
                    </InputLabel>
                    <Select
                      labelId="equipment-select-label"
                      value={equipment}
                      onChange={(e) => setEquipment(e.target.value)}
                      style={{ color: "white" }}
                    >
                      {trucks.map((truck) => (
                        <MenuItem key={truck.Number} value={truck.Number}>
                          {truck.Number}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )
              ) : (
                <TextField
                  value={equipment}
                  onChange={(e) => setEquipment(e.target.value)}
                  InputProps={{
                    style: { color: "white" },
                  }}
                  variant="standard"
                />
              )}
            </TableCell>
          </TableRow>
          {/* Status with Dropdown */}
          <TableRow>
            <TableCell style={headerStyle}>Status</TableCell>
            <TableCell style={cellStyle}>
              <TextField
                select
                value={status}
                onChange={(e) => setStatus(e.target.value)}
                InputProps={{
                  style: { color: "white" },
                }}
                variant="standard"
                fullWidth
              >
                <MenuItem value="InProgress">In Progress</MenuItem>
                <MenuItem value="Delivered">Delivered</MenuItem>
                <MenuItem value="None">None</MenuItem>
              </TextField>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <Button
        variant="contained"
        color="primary"
        onClick={handleSave}
        style={{ margin: "10px" }}
      >
        Save
      </Button>
    </TableContainer>
  );
}

export default OrderDetails;
