import React, { useState, useEffect } from "react";
import {
  Card,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Toolbar,
  Typography,
  Pagination,
  Paper,
  Box,
  ToggleButton,
  ToggleButtonGroup,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import Spinner from "./Spinner";
import SideNavBar from "./SideNavBar";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";
import "./OrderHistory.css";

function OrderHistory() {
  const [loads, setLoads] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [statusFilter, setStatusFilter] = useState("InProgress");
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const navigate = useNavigate();

  // State for Delete Confirmation Dialog
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [selectedLoad, setSelectedLoad] = useState(null);

  useEffect(() => {
    setLoads([]);
    const fetchLoads = async () => {
      const storedUserId = sessionStorage.getItem("storageUserId");
      setIsLoading(true);
      try {
        const response = await fetch(
          `/api/fetch-loads?userId=${encodeURIComponent(
            storedUserId
          )}&status=${encodeURIComponent(statusFilter)}`,
          {
            method: "GET",
            headers: { "Content-Type": "application/json" },
          }
        );
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        // Normalize status values if necessary
        const normalizedData = data.result.map((item) => ({
          ...item,
          Status: item.Status || "None",
        }));
        setLoads(normalizedData);
      } catch (error) {
        console.error("Failed to fetch Loads data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchLoads();
  }, [statusFilter]); // Re-fetch when statusFilter changes

  // Handle status filter change
  const handleStatusFilter = (event, newStatus) => {
    if (newStatus !== null) {
      setStatusFilter(newStatus);
      setCurrentPage(1); // Reset to first page on filter change
    }
  };

  // Handle search input change
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1); // Reset to first page on search
  };

  // Filter based on search term only
  const filteredItems = loads.filter((item) => {
    const searchLower = searchTerm.toLowerCase();
    return (
      item.CustomerOrderNumber?.toString()
        .toLowerCase()
        .includes(searchLower) ||
      item.OrderNumber?.toLowerCase().includes(searchLower) ||
      item.Company?.toLowerCase().includes(searchLower) ||
      item.PickupDate?.toLowerCase().includes(searchLower) ||
      item.PickupLocation?.toLowerCase().includes(searchLower) ||
      item.DeliveryDate?.toLowerCase().includes(searchLower) ||
      item.DeliveryLocation?.toLowerCase().includes(searchLower) ||
      item.CarrierOrEquipment?.toLowerCase().includes(searchLower) ||
      item.Status?.toLowerCase().includes(searchLower)
    );
  });

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredItems.slice(indexOfFirstItem, indexOfLastItem);

  const darkTheme = createTheme({
    palette: {
      mode: "dark",
      background: {
        default: "#000000",
        paper: "#000000",
      },
      text: {
        primary: "#ffffff",
        secondary: "#aaaaaa",
      },
      primary: {
        main: "#3c94fc",
      },
    },
  });

  // Handler to open Edit page
  const handleEdit = (load) => {
    sessionStorage.setItem("selectedLoadNumber", load.CustomerOrderNumber);
    navigate("/edit-order");
  };

  // Handlers for Delete functionality
  const handleDeleteClick = (load) => {
    setSelectedLoad(load);
    setIsDeleteDialogOpen(true);
  };

  const handleConfirmDelete = async () => {
    if (!selectedLoad) return;
    const { CustomerOrderNumber: loadNumber } = selectedLoad;
    setIsLoading(true);
    try {
      const response = await fetch("/api/delete-load", {
        method: "POST", 
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ loadNumber }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      // Remove the deleted load from state
      alert("Load deleted successfully!");
      setLoads((prevLoads) =>
        prevLoads.filter((load) => load.CustomerOrderNumber !== loadNumber)
      );
    } catch (error) {
      console.error("Failed to delete load:", error);
      alert(`Error deleting load: ${error.message}`);
    } finally {
      setIsLoading(false);
      setIsDeleteDialogOpen(false);
      setSelectedLoad(null);
    }
  };

  const handleCancelDelete = () => {
    setIsDeleteDialogOpen(false);
    setSelectedLoad(null);
  };

  return (
    <ThemeProvider theme={darkTheme}>
      <div className="order-history-container">
        <SideNavBar />
        <div className="order-history-window">
          <Toolbar style={{ minHeight: "83px" }} />
          <div className="order-history-body">
            <Card sx={{ maxWidth: "100%", overflowX: "auto", m: 2 }}>
              <Typography
                variant="h4"
                component="div"
                sx={{
                  m: 2,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                Order History
              </Typography>

              {/* Status Filter */}
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                width="100%"
                sx={{ mb: 2 }}
              >
                <ToggleButtonGroup
                  value={statusFilter}
                  exclusive
                  onChange={handleStatusFilter}
                  aria-label="status filter"
                >
                  <ToggleButton value="InProgress" aria-label="In Progress">
                    In Progress
                  </ToggleButton>
                  <ToggleButton value="None" aria-label="None">
                    None
                  </ToggleButton>
                  <ToggleButton value="Delivered" aria-label="Delivered">
                    Delivered
                  </ToggleButton>
                  <ToggleButton value="All" aria-label="All">
                    All
                  </ToggleButton>
                </ToggleButtonGroup>
              </Box>

              {/* Search Field */}
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                width="100%"
              >
                <TextField
                  variant="outlined"
                  value={searchTerm}
                  onChange={handleSearchChange}
                  placeholder="Search by any column"
                  margin="normal"
                  sx={{
                    m: 2,
                    width: "50%",
                    input: { color: "#fff" },
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "#555",
                      },
                      "&:hover fieldset": {
                        borderColor: "#777",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#3c94fc",
                      },
                    },
                  }}
                  InputLabelProps={{
                    style: { color: "#fff" },
                  }}
                />
              </Box>

              {isLoading ? (
                <Box
                  sx={{
                    m: 2,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  <Spinner sx={{ color: "#3c94fc" }} />
                </Box>
              ) : (
                <TableContainer component={Paper}>
                  <Table stickyHeader aria-label="order history table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Customer Order#</TableCell>
                        <TableCell>Order#</TableCell>
                        <TableCell>Company</TableCell>
                        <TableCell>Pickup Date</TableCell>
                        <TableCell>Pickup Location</TableCell>
                        <TableCell>Delivery Date</TableCell>
                        <TableCell>Delivery Location</TableCell>
                        <TableCell>Carrier/Equipment</TableCell>
                        <TableCell>Customs</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {currentItems.length > 0 ? (
                        currentItems.map((item, index) => (
                          <TableRow key={index}>
                            <TableCell>{item.CustomerOrderNumber}</TableCell>
                            <TableCell>{item.OrderNumber || "N/A"}</TableCell>
                            <TableCell>{item.Company}</TableCell>
                            <TableCell>
                              {new Date(item.PickupDate).toLocaleDateString()}
                            </TableCell>
                            <TableCell>{item.PickupLocation}</TableCell>
                            <TableCell>
                              {new Date(item.DeliveryDate).toLocaleDateString()}
                            </TableCell>
                            <TableCell>{item.DeliveryLocation}</TableCell>
                            <TableCell>{item.CarrierOrEquipment}</TableCell>
                            <TableCell>{item.Customs ? "Yes" : "No"}</TableCell>
                            <TableCell>{item.Status}</TableCell>
                            <TableCell>
                              {/* Edit Button */}
                              <IconButton
                                onClick={() => handleEdit(item)}
                                sx={{ color: "#fff" }}
                                aria-label="edit"
                              >
                                <EditIcon />
                              </IconButton>
                              {/* Delete Button */}
                              <IconButton
                                onClick={() => handleDeleteClick(item)}
                                sx={{ color: "#fff" }}
                                aria-label="delete"
                              >
                                <DeleteIcon />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <TableRow>
                          <TableCell colSpan={10} align="center">
                            No data found
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                  <Pagination
                    count={Math.ceil(filteredItems.length / itemsPerPage)}
                    page={currentPage}
                    onChange={(event, value) => setCurrentPage(value)}
                    color="primary"
                    sx={{
                      py: 2,
                      display: "flex",
                      justifyContent: "center",
                    }}
                    showFirstButton
                    showLastButton
                  />
                </TableContainer>
              )}

              {/* Delete Confirmation Dialog */}
              <Dialog
                open={isDeleteDialogOpen}
                onClose={handleCancelDelete}
                aria-labelledby="delete-dialog-title"
              >
                <DialogTitle id="delete-dialog-title">
                  Confirm Deletion
                </DialogTitle>
                <DialogContent>
                  Are you sure you want to delete Customer Order#{" "}
                  {selectedLoad?.CustomerOrderNumber}?
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleCancelDelete} color="secondary">
                    Cancel
                  </Button>
                  <Button
                    onClick={handleConfirmDelete}
                    color="primary"
                    variant="contained"
                  >
                    Delete
                  </Button>
                </DialogActions>
              </Dialog>
            </Card>
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
}

export default OrderHistory;
