import React, { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { MdReplay, MdArrowDropDown } from "react-icons/md";
import ConfirmationModal from "./ConfirmationModal";
import "./SideNavBar.css";
import { Toolbar } from "@mui/material";

function SideNavBar({ handleNewChat }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalOption, setModalOption] = useState(null);
  const [showCustomsDropdown, setShowCustomsDropdown] = useState(false);
  const [showOrdersDropdown, setShowOrdersDropdown] = useState(false);
  const [showTrucksDropdown, setShowTrucksDropdown] = useState(false); // New state for Trucks dropdown
  const location = useLocation();

  const options = [
    { name: "Customs", icon: "🛂", path: "/customs" },
    { name: "Load Quote", icon: "💲", path: "/load-quote" },
    { name: "Email Generation", icon: "📧", path: "/email-generation" },
    { name: "Orders", icon: "📦", path: "/orders" },
    { name: "PDF Editor", icon: "📝", path: "/edit-pdf" },
    { name: "Trucks Management", icon: "🚚", path: "/trucks" },
  ];

  const customsSubOptions = [
    { name: "PAPS", icon: "📄", path: "/paps" },
    { name: "PARS", icon: "📄", path: "/pars" },
    { name: "Broker List", icon: "📄", path: "/broker-list" },
    { name: "Authorizations", icon: "📝", path: "/document-generation" },
  ];

  const ordersSubOptions = [
    { name: "Order History", icon: "📅", path: "/order-history" },
    { name: "Invoicing", icon: "💰", path: "/invoices" },
    { name: "Send POD", icon: "📤", path: "/create-invoice" },
    // Add more Orders sub-options here if needed
  ];

  const trucksSubOptions = [
    { name: "Truck Payments", icon: "💰", path: "/truck-payments" },
    // Add more Trucks sub-options here if needed
  ];

  const openModal = (option) => {
    setModalOption(option);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setModalOption(null);
  };

  const confirmNewChat = () => {
    if (modalOption) {
      handleNewChat(modalOption);
      closeModal();
    }
  };

  const toggleCustomsDropdown = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setShowCustomsDropdown(!showCustomsDropdown);
  };

  const toggleOrdersDropdown = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setShowOrdersDropdown(!showOrdersDropdown);
  };

  const toggleTrucksDropdown = (e) => {
    // New toggle function for Trucks
    e.preventDefault();
    e.stopPropagation();
    setShowTrucksDropdown(!showTrucksDropdown);
  };

  return (
    <div className="side-nav">
      <Toolbar sx={{ minHeight: "80px" }} />
      {isModalOpen && modalOption && (
        <ConfirmationModal
          open={isModalOpen}
          handleClose={closeModal}
          handleConfirm={confirmNewChat}
          option={modalOption.name}
        />
      )}
      {options.map((option) => (
        <div key={option.name} className="option-container">
          <NavLink
            to={option.path}
            className={`option-chat-btn ${
              location.pathname === option.path ? "active" : ""
            }`}
          >
            <span className="button-icon">{option.icon}</span> {option.name}
          </NavLink>

          {/* Customs Dropdown */}
          {option.name === "Customs" && (
            <>
              <button
                className="dropdown-btn"
                onClick={toggleCustomsDropdown}
                title="Show Sub-options"
                aria-label="Show Sub-options"
              >
                <MdArrowDropDown />
              </button>
              {showCustomsDropdown && (
                <div className="dropdown-menu">
                  {customsSubOptions.map((subOption) => (
                    <NavLink
                      key={subOption.name}
                      to={subOption.path}
                      className={`dropdown-item ${
                        location.pathname === subOption.path ? "active" : ""
                      }`}
                    >
                      <span className="button-icon">{subOption.icon}</span>{" "}
                      {subOption.name}
                    </NavLink>
                  ))}
                </div>
              )}
            </>
          )}

          {/* Orders Dropdown */}
          {option.name === "Orders" && (
            <>
              <button
                className="dropdown-btn"
                onClick={toggleOrdersDropdown}
                title="Show Sub-options"
                aria-label="Show Sub-options"
              >
                <MdArrowDropDown />
              </button>
              {showOrdersDropdown && (
                <div className="dropdown-menu">
                  {ordersSubOptions.map((subOption) => (
                    <NavLink
                      key={subOption.name}
                      to={subOption.path}
                      className={`dropdown-item ${
                        location.pathname === subOption.path ? "active" : ""
                      }`}
                    >
                      <span className="button-icon">{subOption.icon}</span>{" "}
                      {subOption.name}
                    </NavLink>
                  ))}
                </div>
              )}
            </>
          )}

          {/* Trucks Dropdown */}
          {option.name === "Trucks Management" && (
            <>
              <button
                className="dropdown-btn"
                onClick={toggleTrucksDropdown}
                title="Show Sub-options"
                aria-label="Show Sub-options"
              >
                <MdArrowDropDown />
              </button>
              {showTrucksDropdown && (
                <div className="dropdown-menu">
                  {trucksSubOptions.map((subOption) => (
                    <NavLink
                      key={subOption.name}
                      to={subOption.path}
                      className={`dropdown-item ${
                        location.pathname === subOption.path ? "active" : ""
                      }`}
                    >
                      <span className="button-icon">{subOption.icon}</span>{" "}
                      {subOption.name}
                    </NavLink>
                  ))}
                </div>
              )}
            </>
          )}

          {location.pathname === option.path && (
            <button
              className="new-chat-btn"
              onClick={() => openModal(option)}
              title={`Start a new chat in ${option.name}`}
              aria-label={`Start a new chat in ${option.name}`}
            >
              <MdReplay />
            </button>
          )}
        </div>
      ))}
    </div>
  );
}

export default SideNavBar;
