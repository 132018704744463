// PdfEditor.js

import React, { useRef, useState, useEffect } from "react";
import { PDFDocument } from "pdf-lib";
import * as pdfjs from "pdfjs-dist";
import ConfirmationModal from "./ConfirmationModal";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import FormatBoldIcon from "@mui/icons-material/FormatBold";
import FormatItalicIcon from "@mui/icons-material/FormatItalic";
import "./PdfEditor.css";
import {
  AppBar,
  Toolbar,
  Button,
  IconButton,
  Box,
  Grid,
  Typography,
  Slider,
  TextField,
} from "@mui/material";
import SideNavBar from "./SideNavBar";
import UndoIcon from "@mui/icons-material/Undo";
import SaveIcon from "@mui/icons-material/Save";
import DownloadIcon from "@mui/icons-material/Download";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import TextFieldsIcon from "@mui/icons-material/TextFields";
import BrushIcon from "@mui/icons-material/Brush";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";

// Configure PDF.js worker
pdfjs.GlobalWorkerOptions.workerSrc =
  "//cdnjs.cloudflare.com/ajax/libs/pdf.js/2.14.305/pdf.worker.js";

/**
 * PdfEditor Component
 *
 * Props:
 * - combinedPdfBlob (optional): Blob representing the current PDF.
 * - onSave (optional): Callback function to update the parent with the modified PDF blob.
 * - onClose (optional): Callback function to be invoked when the user clicks the close button.
 * - showSideNavBar (optional): Boolean to show/hide the SideNavBar. Defaults to true.
 * - paddingLeft (optional): Left padding for the editor layout. Defaults to "23%".
 * - paddingRight (optional): Right padding for the editor layout. Defaults to "10%".
 * - prefix (optional): Prefix for the filename when downloading. Defaults to "".
 * - number (optional): Number for the filename when downloading. Defaults to "".
 * - matchedBorder (optional): Border information for the filename when downloading. Defaults to "".
 */
function PdfEditor({
  combinedPdfBlob: propCombinedPdfBlob, // Renamed for clarity
  onSave,
  onClose, // New prop for closing the editor
  showSideNavBar = true,
  paddingLeft = "23%",
  paddingRight = "10%",
  prefix = "",
  number = "",
  matchedBorder = "",
}) {
  const canvasRef = useRef(null);
  const [internalPdfBlob, setInternalPdfBlob] = useState(null);
  const [text, setText] = useState("");
  const [pdfDoc, setPdfDoc] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [mouseDown, setMouseDown] = useState(false);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [drawingColor, setDrawingColor] = useState("#FF0000");
  const [strokeWidth, setStrokeWidth] = useState(2);
  const [pdfBuffer, setPdfBuffer] = useState(null);
  const [drawMode, setDrawMode] = useState("draw");
  const [canvasData, setCanvasData] = useState({});
  const [history, setHistory] = useState({});
  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Determine whether to use prop or internal state
  const combinedPdfBlob = propCombinedPdfBlob || internalPdfBlob;

  useEffect(() => {
    const loadPdf = async () => {
      if (combinedPdfBlob) {
        try {
          const buffer = await combinedPdfBlob.arrayBuffer();
          const pdfjsDoc = pdfjs.getDocument({ data: buffer });
          const pdfLibDoc = await PDFDocument.load(buffer);
          const pdf = await pdfjsDoc.promise;
          setTotalPages(pdf.numPages);
          setPdfDoc(pdf);
          setPdfBuffer(pdfLibDoc);
          setCurrentPage(1);
          setCanvasData({});
          setHistory({});
        } catch (error) {
          console.error("Error loading PDF:", error);
          alert("Error loading PDF. Please try again.");
        }
      }
    };

    loadPdf();
  }, [combinedPdfBlob]);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const confirmNewChat = () => {
    setText("");
    setPdfDoc(null);
    setCurrentPage(1);
    setTotalPages(0);
    setMouseDown(false);
    setMousePosition({ x: 0, y: 0 });
    setDrawingColor("#FF0000");
    setStrokeWidth(2);
    setPdfBuffer(null);
    setInternalPdfBlob(null);
    setDrawMode("draw");
    setCanvasData({});
    setHistory({});
    console.log("New chat confirmed.");
    closeModal();
  };

  const handleFileUpload = async (event) => {
    const files = event.target.files;
    if (!files || !files.length) return;
    try {
      const combinedPdfDoc = await PDFDocument.create();
      for (const file of files) {
        if (file.type !== "application/pdf") continue;
        const buffer = await file.arrayBuffer();
        const tempPdfDoc = await PDFDocument.load(buffer);
        const copiedPages = await combinedPdfDoc.copyPages(
          tempPdfDoc,
          tempPdfDoc.getPageIndices()
        );
        copiedPages.forEach((page) => combinedPdfDoc.addPage(page));
      }
      const mergedPdfBytes = await combinedPdfDoc.save();
      const pdfjsDoc = pdfjs.getDocument({ data: mergedPdfBytes });
      const pdf = await pdfjsDoc.promise;
      const pdfLibDoc = await PDFDocument.load(mergedPdfBytes);
      setTotalPages(pdf.numPages);
      setPdfDoc(pdf);
      setPdfBuffer(pdfLibDoc);
      setCurrentPage(1);
      setCanvasData({});
      setHistory({});
      if (!onSave) {
        const mergedPdfBlob = new Blob([mergedPdfBytes], {
          type: "application/pdf",
        });
        setInternalPdfBlob(mergedPdfBlob);
      } else {
        const mergedPdfBlob = new Blob([mergedPdfBytes], {
          type: "application/pdf",
        });
        onSave(mergedPdfBlob);
      }
    } catch (error) {
      console.error("Error combining PDFs:", error);
      alert("Error combining PDFs. Please try again.");
    }
  };

  const startDrawing = (e) => {
    const position = getMousePosition(canvasRef.current, e);
    setMousePosition(position);
    setMouseDown(true);
    if (drawMode === "text") {
      drawText(position.x, position.y);
      saveCanvasData();
      setMouseDown(false);
    }
  };

  const draw = (e) => {
    if (!mouseDown) return;
    const newPosition = getMousePosition(canvasRef.current, e);
    const ctx = canvasRef.current.getContext("2d");
    ctx.beginPath();
    ctx.moveTo(mousePosition.x, mousePosition.y);
    ctx.lineTo(newPosition.x, newPosition.y);
    ctx.strokeStyle = drawingColor;
    ctx.lineWidth = strokeWidth;
    ctx.stroke();
    setMousePosition(newPosition);
  };

  const drawText = (x, y) => {
    const ctx = canvasRef.current.getContext("2d");
    const fontStyle = `${isItalic ? "italic " : ""}${isBold ? "bold " : ""}`;
    ctx.font = `${fontStyle}${strokeWidth * 10}px Helvetica`;
    ctx.fillStyle = drawingColor;
    text.split("\n").forEach((line, i) => {
      ctx.fillText(line, x, y + i * strokeWidth * 10);
    });
  };

  const handleMouseMove = (e) => {
    if (drawMode === "draw") {
      draw(e);
    }
  };

  const handleMouseUp = () => {
    setMouseDown(false);
    if (drawMode === "draw") {
      saveCanvasData();
    }
  };

  const saveCanvasData = () => {
    const dataUrl = canvasRef.current.toDataURL("image/png");
    setCanvasData((prevData) => ({
      ...prevData,
      [currentPage]: dataUrl,
    }));
    setHistory((prevHistory) => ({
      ...prevHistory,
      [currentPage]: [...(prevHistory[currentPage] || []), dataUrl],
    }));
  };

  const modifyPdf = async () => {
    if (!pdfBuffer || !canvasRef.current) return;
    const modifiedPdfDoc = await PDFDocument.create();
    for (let i = 1; i <= pdfBuffer.getPageCount(); i++) {
      const [copiedPage] = await modifiedPdfDoc.copyPages(pdfBuffer, [i - 1]);
      modifiedPdfDoc.addPage(copiedPage);
      if (canvasData[i]) {
        const canvasImage = await modifiedPdfDoc.embedPng(canvasData[i]);
        const { width, height } = copiedPage.getSize();
        copiedPage.drawImage(canvasImage, { x: 0, y: 0, width, height });
      }
    }
    const pdfBytes = await modifiedPdfDoc.save();
    const updatedBlob = new Blob([pdfBytes], { type: "application/pdf" });
    if (onSave) {
      onSave(updatedBlob);
    } else {
      setInternalPdfBlob(updatedBlob);
    }
  };

  const downloadModifiedPdf = () => {
    if (!combinedPdfBlob) {
      alert("No PDF available to download.");
      return;
    }
    const url = URL.createObjectURL(combinedPdfBlob);
    const fileName =
      `${prefix}_${number}_${matchedBorder}.pdf`.replace(/ /g, "_") ||
      "modified-document.pdf";
    const link = document.createElement("a");
    link.href = url;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  const undoLastAction = () => {
    if (!history[currentPage] || history[currentPage].length === 0) {
      alert("No actions to undo.");
      return;
    }
    const newHistory = history[currentPage].slice(0, -1);
    setCanvasData((prevData) => ({
      ...prevData,
      [currentPage]:
        newHistory.length > 0 ? newHistory[newHistory.length - 1] : null,
    }));
    setHistory((prevHistory) => ({
      ...prevHistory,
      [currentPage]: newHistory,
    }));
  };

  const handleDeletePage = async () => {
    if (!pdfBuffer || !pdfDoc) return;

    // Prevent deleting the last page
    if (totalPages <= 1) {
      alert("Cannot delete the last page of the PDF.");
      return;
    }

    // Remove the current page from pdfBuffer
    pdfBuffer.removePage(currentPage - 1);

    // Update canvasData
    setCanvasData((prevData) => {
      const newData = {};
      Object.keys(prevData).forEach((key) => {
        const pageNum = parseInt(key, 10);
        if (pageNum < currentPage) {
          newData[pageNum] = prevData[pageNum];
        } else if (pageNum > currentPage) {
          newData[pageNum - 1] = prevData[pageNum];
        }
        // Skip the currentPage, which we're deleting
      });
      return newData;
    });

    // Update history
    setHistory((prevHistory) => {
      const newHistory = {};
      Object.keys(prevHistory).forEach((key) => {
        const pageNum = parseInt(key, 10);
        if (pageNum < currentPage) {
          newHistory[pageNum] = prevHistory[pageNum];
        } else if (pageNum > currentPage) {
          newHistory[pageNum - 1] = prevHistory[pageNum];
        }
        // Skip the currentPage, which we're deleting
      });
      return newHistory;
    });

    // Save the modified pdfBuffer to bytes
    const pdfBytes = await pdfBuffer.save();

    // Create a new pdfjs pdfDoc from pdfBytes
    const pdfjsDoc = pdfjs.getDocument({ data: pdfBytes });

    // Wait for the pdfjsDoc promise to resolve
    const pdf = await pdfjsDoc.promise;

    // Update totalPages
    const newTotalPages = pdf.numPages;
    setTotalPages(newTotalPages);

    if (currentPage > newTotalPages) {
      setCurrentPage(newTotalPages);
    }

    // Update pdfDoc
    setPdfDoc(pdf);

    // Update pdfBuffer
    const pdfLibDoc = await PDFDocument.load(pdfBytes);
    setPdfBuffer(pdfLibDoc);
  };

  const getMousePosition = (canvas, event) => {
    const rect = canvas.getBoundingClientRect();
    const scaleX = canvas.width / rect.width;
    const scaleY = canvas.height / rect.height;
    const x = (event.clientX - rect.left) * scaleX;
    const y = (event.clientY - rect.top) * scaleY;
    return { x, y };
  };

  useEffect(() => {
    if (!pdfDoc) return;
    if (currentPage > pdfDoc.numPages) {
      setCurrentPage(pdfDoc.numPages);
      return;
    }
    if (currentPage < 1) {
      setCurrentPage(1);
      return;
    }
    let renderTask = null;
    const renderPdfPage = async (pdf, pageNumber) => {
      if (renderTask) {
        renderTask.cancel();
      }
      const page = await pdf.getPage(pageNumber);
      const viewport = page.getViewport({ scale: 2.5 });
      const canvas = canvasRef.current;
      if (!canvas) return;
      const context = canvas.getContext("2d");
      canvas.height = viewport.height;
      canvas.width = viewport.width;
      const renderContext = {
        canvasContext: context,
        viewport: viewport,
      };
      renderTask = page.render(renderContext);
      try {
        await renderTask.promise;
      } catch (error) {
        if (error.name === "RenderingCancelledException") {
          console.log("Rendering cancelled.");
        }
      } finally {
        renderTask = null;
      }
    };
    const currentCanvasData = canvasData[currentPage];
    if (!currentCanvasData) {
      renderPdfPage(pdfDoc, currentPage);
    } else {
      const canvas = canvasRef.current;
      if (!canvas) return;
      const context = canvas.getContext("2d");
      const img = new Image();
      img.src = currentCanvasData;
      img.onload = () => {
        context.clearRect(0, 0, canvas.width, canvas.height);
        context.drawImage(img, 0, 0);
      };
    }
    return () => {
      if (renderTask) {
        renderTask.cancel();
      }
    };
  }, [currentPage, pdfDoc, canvasData]);

  return (
    <div className="App" style={{ position: "relative" }}>
      {/* Optional Close Button on top right if onClose prop is provided */}
      {onClose && (
        <IconButton
          onClick={onClose}
          aria-label="close"
          style={{
            position: "absolute",
            top: "40px",
            right: "40px",
            zIndex: 9000,
            border: "1px solid darkred",
          }}
        >
          <CloseIcon style={{ color: "white" }} />
        </IconButton>
      )}
      {showSideNavBar && <SideNavBar handleNewChat={openModal} />}
      <ConfirmationModal
        open={isModalOpen}
        handleClose={closeModal}
        handleConfirm={confirmNewChat}
        option="Start a New Chat"
      />
      <div className="pdfEditorWindow">
        <Toolbar style={{ minHeight: "87px" }} />
        <div className="pdfEditorBody" style={{ paddingLeft, paddingRight }}>
          <AppBar position="static" color="primary">
            {!pdfDoc && (
              <Button
                variant="contained"
                component="label"
                startIcon={<CloudUploadIcon />}
                color="inherit"
                sx={{
                  backgroundColor: "black",
                  border: "1px solid #3c94fc",
                  "&:hover": {
                    backgroundColor: "black",
                    color: "white",
                    borderColor: "black",
                    border: "1px solid #3c94fc",
                  },
                }}
              >
                Upload PDF(s)
                <input
                  type="file"
                  hidden
                  onChange={handleFileUpload}
                  accept="application/pdf"
                  multiple
                />
              </Button>
            )}

            {pdfDoc && (
              <>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexGrow: 1,
                    backgroundColor: "black",
                  }}
                >
                  <IconButton
                    color="inherit"
                    onClick={() => setCurrentPage((v) => Math.max(v - 1, 1))}
                  >
                    <NavigateBeforeIcon />
                  </IconButton>
                  <Typography variant="h6" sx={{ mx: 2 }}>
                    Page {currentPage} of {totalPages}
                  </Typography>
                  <IconButton
                    color="inherit"
                    onClick={() =>
                      setCurrentPage((v) => Math.min(v + 1, totalPages))
                    }
                  >
                    <NavigateNextIcon />
                  </IconButton>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    gap: 1,
                    backgroundColor: "black",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    variant="contained"
                    color="secondary"
                    startIcon={<UndoIcon />}
                    onClick={undoLastAction}
                    sx={{
                      backgroundColor: "black",
                      border: "1px solid #3c94fc",
                    }}
                  >
                    Undo
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    startIcon={<SaveIcon />}
                    onClick={modifyPdf}
                    sx={{
                      backgroundColor: "black",
                      border: "1px solid #3c94fc",
                    }}
                  >
                    Save Changes
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    startIcon={<DownloadIcon />}
                    onClick={downloadModifiedPdf}
                    sx={{
                      backgroundColor: "black",
                      border: "1px solid #3c94fc",
                    }}
                  >
                    Download
                  </Button>
                  {totalPages > 1 && (
                    <Button
                      variant="contained"
                      color="secondary"
                      startIcon={<DeleteIcon />}
                      onClick={handleDeletePage}
                      sx={{
                        backgroundColor: "black",
                        border: "1px solid #3c94fc",
                      }}
                    >
                      Delete Page
                    </Button>
                  )}
                </Box>
                <Box sx={{ height: "20px", backgroundColor: "black" }}></Box>
                <Box
                  className="toolPanel"
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 2,
                    backgroundColor: "black",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      flexGrow: 1,
                      flexDirection: "row",
                      gap: 2,
                      backgroundColor: "black",
                    }}
                  >
                    <Button
                      variant={drawMode === "text" ? "contained" : "outlined"}
                      startIcon={<TextFieldsIcon />}
                      onClick={() => setDrawMode("text")}
                      fullWidth
                      sx={{
                        backgroundColor: "black",
                        color: "white",
                        border: "1px solid #3c94fc",
                      }}
                    >
                      Text
                    </Button>
                    <Button
                      variant={drawMode === "draw" ? "contained" : "outlined"}
                      startIcon={<BrushIcon />}
                      onClick={() => setDrawMode("draw")}
                      fullWidth
                      sx={{
                        backgroundColor: "black",
                        color: "white",
                        border: "1px solid #3c94fc",
                      }}
                    >
                      Draw
                    </Button>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexGrow: 1,
                      flexDirection: "row",
                      gap: 2,
                    }}
                  >
                    <Typography gutterBottom>Color</Typography>
                    <input
                      type="color"
                      value={drawingColor}
                      onChange={(e) => setDrawingColor(e.target.value)}
                      style={{ width: "50px", height: "50px" }}
                      className="colorPicker"
                    />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      flexGrow: 1,
                      flexDirection: "row",
                      gap: 2,
                    }}
                  >
                    <Typography gutterBottom>Stroke Width</Typography>
                    <Slider
                      value={strokeWidth}
                      onChange={(e, val) => setStrokeWidth(val)}
                      aria-labelledby="stroke-width-slider"
                      valueLabelDisplay="auto"
                      step={1}
                      marks
                      min={1}
                      max={10}
                    />
                  </Box>
                  {drawMode === "text" && (
                    <>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          flexGrow: 1,
                          flexDirection: "row",
                          gap: 2,
                        }}
                      >
                        <TextField
                          placeholder="Enter Text"
                          rows={4}
                          variant="outlined"
                          value={text}
                          sx={{
                            backgroundColor: "black",
                            border: "1px solid #3c94fc",
                          }}
                          onChange={(e) => setText(e.target.value)}
                          fullWidth
                          InputLabelProps={{ style: { color: "white" } }}
                          InputProps={{
                            style: { color: "white", backgroundColor: "#333" },
                          }}
                          className="textInput"
                        />
                        <ToggleButtonGroup
                          aria-label="text formatting"
                          sx={{ marginTop: 2 }}
                          exclusive={false}
                        >
                          <ToggleButton
                            value="bold"
                            selected={isBold}
                            onChange={() => setIsBold(!isBold)}
                            aria-label="bold"
                            sx={{
                              color: "white",
                              borderColor: "#3c94fc",
                              "&.Mui-selected": {
                                backgroundColor: "#3c94fc",
                                color: "black",
                              },
                            }}
                          >
                            <FormatBoldIcon />
                          </ToggleButton>
                          <ToggleButton
                            value="italic"
                            selected={isItalic}
                            onChange={() => setIsItalic(!isItalic)}
                            aria-label="italic"
                            sx={{
                              color: "white",
                              borderColor: "#3c94fc",
                              "&.Mui-selected": {
                                backgroundColor: "#3c94fc",
                                color: "black",
                              },
                            }}
                          >
                            <FormatItalicIcon />
                          </ToggleButton>
                        </ToggleButtonGroup>
                      </Box>
                    </>
                  )}
                </Box>
              </>
            )}
          </AppBar>
          {pdfDoc && (
            <Grid container spacing={2}>
              <Grid item xs={12} md={10}>
                <canvas
                  ref={canvasRef}
                  onMouseDown={startDrawing}
                  onMouseMove={handleMouseMove}
                  onMouseUp={handleMouseUp}
                  onMouseLeave={handleMouseUp}
                  className="pdfCanvas"
                ></canvas>
              </Grid>
              <Grid item xs={12} md={4}>
                {/* Additional space for annotations */}
              </Grid>
            </Grid>
          )}
        </div>
      </div>
    </div>
  );
}

export default PdfEditor;
