import React, { useState, useEffect, useMemo, useRef } from "react";
import { PDFDocument, StandardFonts, rgb } from "pdf-lib";
import Modal from "react-modal";
import bwipjs from "bwip-js";
import { TextField, Button, Toolbar } from "@mui/material";
import PdfEditor from "./PdfEditor";
import MessageModal from "./MessageModal";
import SideNavBar from "./SideNavBar";
import ConfirmationModal from "./ConfirmationModal";
import "./Home.css";

const pars_borders = [
  "Huntingdon, BC",
  "Pacific Highway, BC",
  "Osoyoos, BC",
  "Kingsgate, BC",
  "Coutts, AB",
  "North Portal, SK",
  "Regway, SK",
  "Emerson, MB",
  "Fort Erie, ON",
  "Windsor, ON",
  "Sarnia, ON",
  "Prescott, ON",
  "Lacolle, QC",
  "Stanstead, QC",
  "St. Stephen, NB",
  "Woodstock, NB",
];
const paps_borders = [
  "Sumas, WA",
  "Blaine, WA",
  "Oroville, WA",
  "Eastport, ID",
  "Sweet Grass, MT",
  "Portal, ND",
  "Raymond, MT",
  "Pembina, ND",
  "Buffalo, NY",
  "Detroit, MI",
  "Port Huron, MI",
  "Ogdensburg, NY",
  "Champlain, NY",
  "Derby Line, VT",
  "Calais, ME",
  "Houlton, ME",
];

function App() {
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState("");
  const [number, setNumber] = useState("");
  const [border, setBorder] = useState("");
  const [matchedBorder, setMatchedBorder] = useState("");
  const [storageUserId, setStorageUserId] = useState("");
  const [userCompanyName, setUserCompanyName] = useState("");
  const [userCustomsEmail, setUserCustomsEmail] = useState("");
  const [userFirstName, setUserFirstName] = useState("");
  const [userLastName, setUserLastName] = useState("");
  const [userPhone, setUserPhone] = useState("");
  const [userAddress, setUserAddress] = useState("");
  const [userCity, setUserCity] = useState("");
  const [userState, setUserState] = useState("");
  const [userZip, setUserZip] = useState("");
  const [userCompanyLogo, setUserCompanyLogo] = useState("");
  const [userCompanyWebsite, setUserCompanyWebsite] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [loadCompany, setLoadCompany] = useState("");
  const [customsBroker, setCustomsBroker] = useState("");
  const [isPdfEditorOpen, setIsPdfEditorOpen] = useState(false);
  const [combinedPdfBlob, setCombinedPdfBlob] = useState(null);
  const [toEmail, setToEmail] = useState("");
  const [brokerPhone, setBrokerPhone] = useState("");
  const [prefix, setPrefix] = useState("");
  const [pdfFiles, setPdfFiles] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  let dateString2 = "";
  const fileInputRef = useRef(null);
  const [brokerList, setBrokerList] = useState([]);
  const [loadCompanyList, setLoadCompanyList] = useState([]);
  const [isBrokersLoading, setIsBrokersLoading] = useState(false);
  const [isLoadCompaniesLoading, setIsLoadCompaniesLoading] = useState(false);

  const fetchAllLoadCompanies = async () => {
    setIsLoadCompaniesLoading(true); // Start loading
    try {
      const response = await fetch("/api/fetch-load-companies");
      if (!response.ok) {
        throw new Error("Failed to fetch load companies");
      }
      const data = await response.json();
      setLoadCompanyList(data.result || []);
    } catch (error) {
      console.error("Error fetching all load companies:", error);
      setMessage("Error fetching load companies.");
      setStatus("Bad");
    } finally {
      setIsLoadCompaniesLoading(false); // End loading
    }
  };

  const updateCustomsField = async (customerOrderNumber) => {
    try {
      const response = await fetch(`/api/update-load-customs`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ CustomerOrderNumber: customerOrderNumber }),
      });

      const data = await response.json();

      if (response.ok && data.success) {
        console.log("Customs field updated successfully.");
      } else {
        throw new Error(data.error || "Failed to update Customs field.");
      }
    } catch (error) {
      setMessage("Error updating Customs field.");
    }
  };

  useEffect(() => {
    // Fetch all brokers once on mount
    fetchAllBrokers();
    // Fetch all load companies once on mount
    fetchAllLoadCompanies();
  }, []);

  useEffect(() => {
    if (prefix === "PAPS") fetchPapsNumberForPrefix();
    else if (prefix === "PARS") fetchParsNumberForPrefix();
  }, [prefix]);

  const closeMessageModal = () => {
    setMessage("");
    setStatus("");
  };

  const fetchAllBrokers = async () => {
    setIsBrokersLoading(true); // Start loading
    try {
      const response = await fetch("/api/fetch-brokers");
      if (!response.ok) {
        throw new Error("Failed to fetch broker list");
      }
      const data = await response.json();
      setBrokerList(data.result || []);
    } catch (error) {
      console.error("Error fetching all brokers:", error);
      setMessage("Error fetching brokers.");
      setStatus("Bad");
    } finally {
      setIsBrokersLoading(false); // End loading
    }
  };

  const handleBrokerSelect = (event) => {
    const selectedName = event.target.value;
    setCustomsBroker(selectedName);

    const selectedBrokerObj = brokerList.find((b) => b.Name === selectedName);

    if (selectedBrokerObj) {
      if (prefix === "PARS") {
        setToEmail(selectedBrokerObj.ParsEmail || "");
      } else if (prefix === "PAPS") {
        setToEmail(selectedBrokerObj.PapsEmail || "");
      }
      setBrokerPhone(selectedBrokerObj.PhoneNumber || "");
    }
  };

  useEffect(() => {
    const storedUserId = sessionStorage.getItem("storageUserId");
    const storedCompanyName = sessionStorage.getItem("storageCompanyName");
    const storedCustomsEmail = sessionStorage.getItem("storageCustomsEmail");
    const storedFirstName = sessionStorage.getItem("storageFirstName");
    const storedLastName = sessionStorage.getItem("storageLastName");
    const storedPhone = sessionStorage.getItem("storagePhone");
    const storedAddress = sessionStorage.getItem("storageAddress");
    const storedCity = sessionStorage.getItem("storageCity");
    const storedState = sessionStorage.getItem("storageState");
    const storedZip = sessionStorage.getItem("storageZip");
    const companyLogoUrl = sessionStorage.getItem("storageCompanyLogo");
    const companyWebsite = sessionStorage.getItem("storageCompanyWebsite");
    if (storedUserId) {
      setStorageUserId(storedUserId);
    }
    if (storedCompanyName) {
      setUserCompanyName(storedCompanyName);
    }
    if (storedCustomsEmail) {
      setUserCustomsEmail(storedCustomsEmail);
    }
    if (storedFirstName) {
      setUserFirstName(storedFirstName);
    }
    if (storedLastName) {
      setUserLastName(storedLastName);
    }
    if (storedPhone) {
      setUserPhone(storedPhone);
    }
    if (storedAddress) {
      setUserAddress(storedAddress);
    }
    if (storedCity) {
      setUserCity(storedCity);
    }
    if (storedState) {
      setUserState(storedState);
    }
    if (storedZip) {
      setUserZip(storedZip);
    }
    if (companyLogoUrl) {
      setUserCompanyLogo(companyLogoUrl);
    }
    if (companyWebsite) {
      setUserCompanyWebsite(companyWebsite);
    }
  }, []);

  const togglePdfEditorModal = () => {
    setIsPdfEditorOpen(!isPdfEditorOpen);
  };

  const handleNewChat = () => {
    setMessage("");
    setNumber("");
    setBorder("");
    setMatchedBorder("");
    setLoadCompany("");
    setCustomsBroker("");
    setIsPdfEditorOpen(false);
    setCombinedPdfBlob(null);
    setToEmail("");
    setBrokerPhone("");
    setPrefix("");
    setPdfFiles([]);
    dateString2 = "";
    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const confirmNewChat = () => {
    handleNewChat();
    closeModal();
  };

  const generatePdf = async () => {
    if (pdfFiles.length === 0) {
      setMessage("Please select at least one PDF file before generating.");
      setStatus("Bad");
      return;
    }

    if (!number) {
      setMessage("Please provide a number for the barcode.");
      setStatus("Bad");
      return;
    }

    setIsLoading(true);

    try {
      // Create a fresh PDF and a single "cover" page (for the barcode and info).
      const mergedPdf = await PDFDocument.create();
      const customPage = mergedPdf.addPage();
      const { width, height } = customPage.getSize();
      const fontSize = 30;
      const font = await mergedPdf.embedFont(StandardFonts.Helvetica);

      const text1 = `${prefix} ${number}`;
      const text2 = `Crossing at ${matchedBorder}`;

      // Create the date/time for the main label:
      const nowUTC = new Date();
      const currentDate = new Date(
        nowUTC.toLocaleString("en-US", { timeZone: "America/Vancouver" })
      );
      currentDate.setHours(currentDate.getHours() + 2);
      if (currentDate.getMinutes() >= 30) {
        currentDate.setMinutes(0);
        currentDate.setHours(currentDate.getHours() + 1);
      } else {
        currentDate.setMinutes(0);
      }
      const options = {
        day: "2-digit",
        month: "long",
        year: "numeric",
        hour: "numeric",
        minute: "2-digit",
        hour12: true,
      };
      const dateString = currentDate.toLocaleString("en-US", options);
      const text3 = `On ${dateString}`;

      // Center points
      const centerX = width / 2;
      const centerY = height / 2;

      // Generate the barcode image:
      const barcodeImage = await generateBarcodeImage(number, mergedPdf);
      if (!barcodeImage) {
        setMessage("Error generating barcode. Please try again.");
        setStatus("Bad");
        setIsLoading(false);
        return;
      }

      const barcodeHeight = 60;
      customPage.drawImage(barcodeImage, {
        x: centerX - barcodeImage.width / 2,
        y: centerY + barcodeHeight,
        width: barcodeImage.width,
        height: barcodeHeight,
      });

      // Draw the main text:
      const text1Width = font.widthOfTextAtSize(text1, fontSize);
      const text2Width = font.widthOfTextAtSize(text2, fontSize);
      const text3Width = font.widthOfTextAtSize(text3, fontSize);

      customPage.drawText(text1, {
        x: centerX - text1Width / 2,
        y: centerY,
        size: fontSize,
        font,
      });
      customPage.drawText(text2, {
        x: centerX - text2Width / 2,
        y: centerY - 40,
        size: fontSize,
        font,
      });
      customPage.drawText(text3, {
        x: centerX - text3Width / 2,
        y: centerY - 80,
        size: fontSize,
        font,
      });

      // -----------------------------
      // ADD FOOTER NOTE (pdf-lib way)
      // -----------------------------
      // We’ll draw a horizontal line and a small “Generated by” text,
      // similar to how you’d do it in jsPDF, but using pdf-lib’s syntax.
      customPage.drawLine({
        start: { x: 40, y: 40 },
        end: { x: width - 40, y: 40 },
        thickness: 0.5,
        color: rgb(0, 0, 0), // black line
      });
      customPage.drawText("Generated by LoadMinds ©", {
        x: 40,
        y: 25,
        size: 9,
        font,
        color: rgb(0.39, 0.39, 0.39),
      });
      // -----------------------------

      // Now append the user-uploaded PDF pages:
      for (const file of pdfFiles) {
        const fileBytes = await file.arrayBuffer();
        const pdfDoc = await PDFDocument.load(fileBytes);
        const pages = await mergedPdf.copyPages(
          pdfDoc,
          pdfDoc.getPageIndices()
        );
        pages.forEach((page) => mergedPdf.addPage(page));
      }

      // Save as Blob
      const mergedPdfBytes = await mergedPdf.save();
      const mergedPdfBlob = new Blob([mergedPdfBytes], {
        type: "application/pdf",
      });

      setCombinedPdfBlob(mergedPdfBlob);
      togglePdfEditorModal();
    } catch (error) {
      console.error("Error generating PDF:", error);
      setMessage("An unexpected error occurred while generating the PDF.");
      setStatus("Bad");
    } finally {
      setIsLoading(false);
    }
  };

  const fetchPapsNumberForPrefix = async () => {
    try {
      let storedUserId = sessionStorage.getItem("storageUserId");
      let storedPapsStart = sessionStorage.getItem("storagePapsStart");
      console.log("storedUserId: ", storedUserId);
      const response = await fetch(
        `/api/fetch-new-number?userId=${encodeURIComponent(
          storedUserId
        )}&papsStart=${encodeURIComponent(storedPapsStart)}`,
        {
          method: "GET",
          headers: { "Content-Type": "application/json" },
        }
      );
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      if (data.result && data.result.length > 0) {
        setNumber(data.result[0].Number);
      } else {
        setMessage("No number found in the response.");
      }
    } catch (error) {
      console.error("Failed to fetch number: ", error.message);
    }
  };

  const fetchParsNumberForPrefix = async () => {
    try {
      let storedUserId = sessionStorage.getItem("storageUserId");
      let storedParsStart = sessionStorage.getItem("storageParsStart");
      const response = await fetch(
        `/api/get-pars-new-number?userId=${encodeURIComponent(
          storedUserId
        )}&parsStart=${encodeURIComponent(storedParsStart)}`,
        {
          method: "GET",
          headers: { "Content-Type": "application/json" },
        }
      );
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      if (data.result && data.result.length > 0) {
        setNumber(data.result[0].Number);
      } else {
        setMessage("No number found in the response.");
      }
    } catch (error) {
      console.error("Failed to fetch number: ", error.message);
    }
  };

  const sortedBorders = useMemo(() => {
    return [...pars_borders, ...paps_borders].sort((a, b) =>
      a.localeCompare(b)
    );
  }, []);

  const handleFileChange = (e) => {
    setPdfFiles(Array.from(e.target.files));
  };

  const generateBarcodeImage = async (data, mergedPdf) => {
    if (!data) {
      throw new Error("Barcode data is missing");
    }

    try {
      // Create a canvas element dynamically
      const canvas = document.createElement("canvas");
      // Generate barcode
      await bwipjs.toCanvas(canvas, {
        bcid: "code128", // Barcode type (Code 128)
        text: data, // Barcode text
        scale: 3,
        height: 10,
        includetext: true,
        textxalign: "center",
      });

      // Get the image data from the canvas
      const barcodeDataUrl = canvas.toDataURL("image/png");
      const barcodeImageBytes = await fetch(barcodeDataUrl).then((res) =>
        res.arrayBuffer()
      );

      return await mergedPdf.embedPng(barcodeImageBytes);
    } catch (err) {
      console.error("Error generating barcode:", err);
      throw err;
    }
  };

  // -----------------------
  // 2) Update PAPS row
  // -----------------------
  const updatePapsRow = async () => {
    try {
      const body = {
        Number: number,
        Border: matchedBorder,
        Broker: customsBroker,
        Email: toEmail,
        Phone: brokerPhone,
        Company: userCompanyName,
        LoadCompany: loadCompany,
        Status: "Active",
        PDF: "Empty",
        UserId: storageUserId,
        Date: dateString2,
      };
      const response = await fetch("/api/update-paps", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });
      const data = await response.json();
      if (response.ok) {
        setMessage(`Update successful: ${data.message}`);
      } else {
        throw new Error(data.error || "Failed to update the row");
      }
    } catch (error) {
      setMessage(`Error: ${error.message}`);
    }
  };

  // -----------------------
  // 3) Update PARS row
  // -----------------------
  const updateParsRow = async (pdfUrl) => {
    try {
      const body = {
        Number: number,
        Border: matchedBorder,
        Broker: customsBroker,
        Email: toEmail,
        Phone: brokerPhone,
        Company: userCompanyName,
        LoadCompany: loadCompany,
        Status: "Active",
        PDF: "Empty",
        UserId: storageUserId,
        Date: dateString2,
      };
      const response = await fetch("/api/update-pars", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });
      const data = await response.json();
      if (response.ok) {
        setMessage(`Update successful: ${data.message}`);
      } else {
        throw new Error(data.error || "Failed to update the row");
      }
    } catch (error) {
      setMessage(`Error: ${error.message}`);
    }
  };

  // -----------------------
  // 4) Send Email + upload PDF to Supabase
  // -----------------------
  const sendEmail = async () => {
    if (!combinedPdfBlob) {
      setMessage("Please generate a PDF first.");
      setStatus("Bad");
      return;
    }

    setIsLoading(true);
    setStatus("Waiting");
    setMessage("Analyzing custom documents and sending email...");
    try {
      // Set dateString2 for storing in DB
      const dateOnlyOptions = {
        day: "2-digit",
        month: "long",
        year: "numeric",
      };
      const nowUTC = new Date();
      const currentDate = new Date(
        nowUTC.toLocaleString("en-US", { timeZone: "America/Vancouver" })
      );
      const dateOnlyString = currentDate.toLocaleString(
        "en-US",
        dateOnlyOptions
      );
      dateString2 = dateOnlyString;

      // 1) Send to backend just for reference (optional)
      sendNumberToBackend(number);

      // 4) Actually send the email with the file as usual
      await sendEmailWithAttachment(
        toEmail,
        `${prefix} ${number} ${matchedBorder}`,
        combinedPdfBlob,
        "Thanks."
      );

      // 5) Update your DB row with the PDF URL
      if (prefix === "PARS") {
        await updateParsRow();
      } else if (prefix === "PAPS") {
        await updatePapsRow();
      }

      // 6) Update the Customs field in the Loads table
      await updateCustomsField(loadCompany);

      setMessage("Email sent successfully.");
      setStatus("Good");

      // Re-fetch load companies to update the dropdown if needed
      await fetchAllLoadCompanies();
    } catch (error) {
      console.error("Error sending email:", error);
      setMessage("An error occurred while sending the email.");
      setStatus("Bad");
    } finally {
      // Optionally download locally
      downloadPDF(combinedPdfBlob, `${prefix} ${number} ${matchedBorder}`);
      setIsLoading(false);
    }
  };

  const downloadPDF = (blob, fileName) => {
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.href = url;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  function sendNumberToBackend(number) {
    const url = "/api/store-number";
    const data = { number: number };
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (response.ok) return response.json();
        else throw new Error("Network response was not ok.");
      })
      .then((data) => console.log("Success:", data))
      .catch((error) => console.error("Error:", error));
  }

  const sendEmailWithAttachment = async (
    toEmail,
    subject,
    attachment,
    body
  ) => {
    const emails = toEmail.split(",");
    const primaryEmail = emails.shift();
    const ccEmails = emails.join(",");
    const replyTo = userCustomsEmail;
    const formData = new FormData();
    formData.append("firstName", userFirstName);
    formData.append("lastName", userLastName);
    formData.append("companyName", userCompanyName);
    formData.append("phone", userPhone);
    formData.append("toEmail", primaryEmail);
    formData.append("storageUserId", storageUserId);
    formData.append("ccEmails", ccEmails);
    formData.append("subject", subject);
    formData.append("body", body);
    formData.append("attachment", attachment);
    formData.append("replyTo", replyTo);
    formData.append("number", number);
    formData.append("address", userAddress);
    formData.append("city", userCity);
    formData.append("state", userState);
    formData.append("zip", userZip);
    formData.append("companyLogo", userCompanyLogo);
    formData.append("companyWebsite", userCompanyWebsite);

    try {
      const response = await fetch("/api/send-email-with-attachment", {
        method: "POST",
        body: formData,
      });
      const data = await response.json();
      if (response.ok) {
        setMessage(data.message);
      } else {
        setMessage(data.error);
      }
    } catch (error) {
      setMessage("An error occurred while sending the email.");
    }
  };

  useEffect(() => {
    const textarea = document.getElementById("toEmail");
    if (!textarea) return;
    const autoResize = () => {
      textarea.style.height = "auto";
      textarea.style.height = textarea.scrollHeight + "px";
    };
    autoResize();
    textarea.addEventListener("input", autoResize);
    return () => textarea.removeEventListener("input", autoResize);
  }, [toEmail]);

  const updateBorderAndPrefix = (e) => {
    const inputBorder = e.target.value.trim();
    setBorder(inputBorder);

    const normalizedInputBorder = inputBorder.toLowerCase();
    const foundParsBorder = pars_borders.find((b) =>
      b.toLowerCase().includes(normalizedInputBorder)
    );
    const foundPapsBorder = paps_borders.find((b) =>
      b.toLowerCase().includes(normalizedInputBorder)
    );
    let newPrefix = "";
    if (foundParsBorder) {
      newPrefix = "PARS";
      setMatchedBorder(foundParsBorder);
    } else if (foundPapsBorder) {
      newPrefix = "PAPS";
      setMatchedBorder(foundPapsBorder);
    } else {
      setMatchedBorder("");
    }
    setPrefix(newPrefix);
  };

  return (
    <div className="customs-container">
      {/* {isLoading && <LoaderOverlay />} */}

      <SideNavBar handleNewChat={openModal} />
      <ConfirmationModal
        open={isModalOpen}
        handleClose={closeModal}
        handleConfirm={confirmNewChat}
        option="Customs"
      />

      <div className="custom-window">
        <Toolbar style={{ minHeight: "83px" }} />
        <div className="custom-body">
          <input
            type="file"
            id="pdfUpload"
            multiple
            accept="application/pdf"
            className="file-input"
            onChange={handleFileChange}
            ref={fileInputRef}
          />

          <div className="label-input-row">
            <label htmlFor="borderSelect" className="input-label label-inline">
              SELECT BORDER :
            </label>
            <select
              id="borderSelect"
              value={border}
              onChange={(e) => {
                setBorder(e.target.value);
                updateBorderAndPrefix(e);
              }}
              className="border-input"
            >
              <option value="">------------------</option>
              {sortedBorders.map((borderOption) => (
                <option key={borderOption} value={borderOption}>
                  {borderOption}
                </option>
              ))}
            </select>
          </div>

          <div className="label-input-row">
            <label htmlFor="customsBroker" className="input-label label-inline">
              SELECT BROKER :
            </label>
            <select
              id="customsBroker"
              value={customsBroker}
              onChange={handleBrokerSelect}
              className="border-input"
              disabled={isBrokersLoading} // Disable during loading
            >
              {isBrokersLoading ? (
                <option>Loading...</option>
              ) : (
                <>
                  <option value="">------------------</option>
                  {brokerList.map((broker) => (
                    <option key={broker.Name} value={broker.Name}>
                      {broker.Name.toUpperCase()}
                    </option>
                  ))}
                </>
              )}
            </select>
          </div>

          <div className="label-input-row">
            <label
              htmlFor="loadCompanySelect"
              className="input-label label-inline"
            >
              SELECT ORDER NUMBER :
            </label>
            <select
              id="loadCompanySelect"
              value={loadCompany}
              onChange={(e) => setLoadCompany(e.target.value)}
              className="border-input"
              disabled={isLoadCompaniesLoading} // Disable during loading
            >
              {isLoadCompaniesLoading ? (
                <option>Loading...</option>
              ) : (
                <>
                  <option value="">------------------</option>
                  {loadCompanyList.map((company) => (
                    <option key={company} value={company}>
                      {company}
                    </option>
                  ))}
                </>
              )}
            </select>
          </div>

          <div className="label-input-row">
            <label htmlFor="toEmail" className="input-label label-inline">
              BROKER'S EMAIL :
            </label>
            <TextField
              type="text"
              id="toEmail"
              value={toEmail}
              onChange={(e) => setToEmail(e.target.value)}
              variant="outlined"
              className="text-field-standard"
              InputProps={{
                style: { color: "white" },
              }}
              InputLabelProps={{ className: "input-label" }}
              fullWidth
            />
          </div>

          <div className="label-input-row">
            <label htmlFor="Number" className="input-label">
              NUMBER :
            </label>
            <input
              type="text"
              value={number}
              onChange={(e) => setNumber(e.target.value)}
              className="border-input"
            />
          </div>

          <Button
            className="generate-button"
            onClick={generatePdf}
            disabled={isLoading}
          >
            GENERATE PDF
          </Button>

          <Button
            className="send-button"
            onClick={sendEmail}
            disabled={isLoading}
            style={{ marginBottom: "30px" }}
          >
            SEND EMAIL
          </Button>

          <Modal
            isOpen={isPdfEditorOpen}
            onRequestClose={togglePdfEditorModal}
            contentLabel="PDF Editor"
            style={{ content: { inset: "3%", overflow: "auto" } }}
            ariaHideApp={false}
          >
            <PdfEditor
              combinedPdfBlob={combinedPdfBlob}
              onSave={(updatedBlob) => setCombinedPdfBlob(updatedBlob)}
              onClose={togglePdfEditorModal} // <- This will close the modal when the icon is clicked
              showSideNavBar={false}
              paddingLeft="12%"
              paddingRight="5%"
              prefix={prefix}
              number={number}
              matchedBorder={matchedBorder}
            />
          </Modal>

          <MessageModal
            open={status !== ""}
            handleClose={closeMessageModal}
            title={
              status === "Good"
                ? "Success"
                : status === "Bad"
                ? "Error"
                : "Processing..."
            }
            message={message}
            status={status}
          />
        </div>
      </div>
    </div>
  );
}

export default App;
