import React, { useState, useEffect } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import SideNavBar from "./SideNavBar";
import ConfirmationModal from "./ConfirmationModal";
import { Toolbar } from "@mui/material";
import {
  Card,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  Pagination,
  Paper,
  Box,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import Spinner from "./Spinner";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import "./Broker.css";

function BrokersList() {
  const [brokers, setBrokers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [newBroker, setNewBroker] = useState({
    brokerName: "",
    papsEmail: "",
    parsEmail: "",
    brokerPhone: "",
  });
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [editedBrokers, setEditedBrokers] = useState([]);

  const [isAddBrokerModalOpen, setIsAddBrokerModalOpen] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  const openAddBrokerModal = () => setIsAddBrokerModalOpen(true);
  const closeAddBrokerModal = () => setIsAddBrokerModalOpen(false);

  const openConfirmationModal = () => setIsConfirmationModalOpen(true);
  const closeConfirmationModal = () => setIsConfirmationModalOpen(false);

  const confirmNewChat = () => {
    closeConfirmationModal();
  };

  useEffect(() => {
    fetchBrokers();
  }, []);

  const fetchBrokers = async () => {
    setIsLoading(true);
    try {
      const response = await fetch("/api/fetch-brokers", {
        headers: { "Content-Type": "application/json" },
      });
      if (!response.ok)
        throw new Error(`HTTP error! Status: ${response.status}`);
      const data = await response.json();
      // Normalize data to match frontend field names
      const fetchedBrokers = data.result.map((b) => ({
        brokerName: b.Name,
        papsEmail: b.PapsEmail,
        parsEmail: b.ParsEmail,
        brokerPhone: b.PhoneNumber,
      }));
      setBrokers(fetchedBrokers);
    } catch (error) {
      console.error("Failed to fetch brokers:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value.toLowerCase());
  };

  const visibleBrokers = brokers.filter((broker) =>
    broker.brokerName.toLowerCase().includes(searchTerm)
  );

  const handleAddBroker = async (event) => {
    event.preventDefault();
    closeAddBrokerModal();
    setIsLoading(true);
    try {
      const response = await fetch("/api/add-broker", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(newBroker),
      });
      if (!response.ok)
        throw new Error(`HTTP error! Status: ${response.status}`);
      await fetchBrokers();
      setNewBroker({
        brokerName: "",
        papsEmail: "",
        parsEmail: "",
        brokerPhone: "",
      });
      alert("Broker added successfully!");
    } catch (error) {
      console.error("Error adding broker:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleEditChange = (e, brokerName) => {
    const { name, value } = e.target;
    setBrokers(
      brokers.map((broker) =>
        broker.brokerName === brokerName ? { ...broker, [name]: value } : broker
      )
    );
    if (!editedBrokers.includes(brokerName)) {
      setEditedBrokers([...editedBrokers, brokerName]);
    }
  };

  const saveChanges = async (broker) => {
    setIsLoading(true);
    try {
      const response = await fetch(`/api/update-broker`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          BrokerName: broker.brokerName,
          ParsEmail: broker.parsEmail,
          PapsEmail: broker.papsEmail,
          BrokerPhone: broker.brokerPhone,
        }),
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      alert("Changes saved successfully!");
      const newEditedBrokers = editedBrokers.filter(
        (name) => name !== broker.brokerName
      );
      setEditedBrokers(newEditedBrokers);
    } catch (error) {
      console.error("Error saving changes:", error.message);
      alert(`Error saving changes: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };
  const handleDeleteBroker = async (brokerName) => {
    const confirmDelete = window.confirm(
      `Are you sure you want to delete ${brokerName}?`
    );
    if (!confirmDelete) return;

    setIsLoading(true);
    try {
      const response = await fetch(`/api/delete-broker`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ brokerName }),
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      alert("Broker deleted successfully!");
      // Filter out the deleted broker from the brokers list
      setBrokers(brokers.filter((broker) => broker.brokerName !== brokerName));
    } catch (error) {
      console.error("Error deleting broker:", error);
      alert(`Error deleting broker: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  const renderEditableCell = (value, broker, fieldName) => {
    const isEditing = editedBrokers.includes(broker.brokerName);

    return isEditing ? (
      <TextField
        type={
          fieldName.includes("Email")
            ? "email"
            : fieldName === "brokerPhone"
            ? "tel"
            : "text"
        }
        name={fieldName}
        value={value}
        onChange={(e) => handleEditChange(e, broker.brokerName)}
        autoFocus
        fullWidth
        variant="outlined"
        InputProps={{
          style: { color: "#fff" },
          endAdornment: (
            <IconButton onClick={() => saveChanges(broker)}>
              <CheckIcon color="success" />
            </IconButton>
          ),
        }}
        InputLabelProps={{
          style: { color: "#fff" },
        }}
      />
    ) : (
      value
    );
  };

  const darkTheme = createTheme({
    palette: {
      mode: "dark",
      background: {
        default: "#000000",
        paper: "#000000",
      },
      text: {
        primary: "#ffffff",
        secondary: "#aaaaaa",
      },
      primary: {
        main: "#3c94fc",
      },
      secondary: {
        main: "#f50057",
      },
    },
    components: {
      MuiPagination: {
        styleOverrides: {
          root: {
            backgroundColor: "#000000",
            padding: "16px 0",
          },
          ul: {
            justifyContent: "center",
          },
        },
      },
      MuiPaginationItem: {
        styleOverrides: {
          root: {
            color: "#fff",
            backgroundColor: "#000000",
            "&.Mui-selected": {
              backgroundColor: "#3c94fc",
              color: "#fff",
            },
            "&:hover": {
              backgroundColor: "#111111",
            },
          },
        },
      },
    },
  });

  return (
    <ThemeProvider theme={darkTheme}>
      <div className="chat-container">
        <SideNavBar handleNewChat={openConfirmationModal} />
        <ConfirmationModal
          open={isConfirmationModalOpen}
          handleClose={closeConfirmationModal}
          handleConfirm={confirmNewChat}
          option="BrokerList"
        />
        <div className="broker-window">
          <Toolbar style={{ minHeight: "83px" }} />
          <div className="broker-body">
            <Card sx={{ maxWidth: "100%", overflowX: "auto", m: 2 }}>
              <Typography
                variant="h4"
                component="div"
                sx={{
                  m: 2,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                Broker Management
              </Typography>
              <Box sx={{ display: "flex", justifyContent: "center", p: 2 }}>
                <TextField
                  placeholder="Search by Broker Name"
                  variant="outlined"
                  value={searchTerm}
                  onChange={handleSearch}
                  sx={{
                    width: "50%",
                    input: { color: "#fff" },
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "#555",
                      },
                      "&:hover fieldset": {
                        borderColor: "#777",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#3c94fc",
                      },
                    },
                  }}
                  InputLabelProps={{
                    style: { color: "#fff" },
                  }}
                />
                <Button
                  onClick={openAddBrokerModal}
                  variant="contained"
                  className="add-button"
                  style={{
                    backgroundColor: "transparent",
                    color: "#fff",
                    border: "1px solid #3c94fc",
                    borderRadius: "15px",
                    padding: "10px 20px",
                  }}
                  sx={{ mx: 2 }}
                >
                  Add Broker
                </Button>
              </Box>
              {isLoading ? (
                <Box
                  sx={{
                    m: 2,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  <Spinner sx={{ color: "#3c94fc" }} />
                </Box>
              ) : (
                <TableContainer component={Paper}>
                  <Table stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell>Broker Name</TableCell>
                        <TableCell>PAPS Email</TableCell>
                        <TableCell>PARS Email</TableCell>
                        <TableCell>Phone Number</TableCell>
                        <TableCell>Action</TableCell>{" "}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {visibleBrokers
                        .slice(
                          (currentPage - 1) * itemsPerPage,
                          currentPage * itemsPerPage
                        )
                        .map((broker) => (
                          <TableRow key={broker.brokerName}>
                            <TableCell>{broker.brokerName}</TableCell>
                            <TableCell>
                              {renderEditableCell(
                                broker.papsEmail,
                                broker,
                                "papsEmail"
                              )}
                            </TableCell>
                            <TableCell>
                              {renderEditableCell(
                                broker.parsEmail,
                                broker,
                                "parsEmail"
                              )}
                            </TableCell>
                            <TableCell>
                              {renderEditableCell(
                                broker.brokerPhone,
                                broker,
                                "brokerPhone"
                              )}
                            </TableCell>
                            <TableCell>
                              <IconButton
                                onClick={() => {
                                  if (
                                    !editedBrokers.includes(broker.brokerName)
                                  ) {
                                    setEditedBrokers([
                                      ...editedBrokers,
                                      broker.brokerName,
                                    ]);
                                  }
                                }}
                              >
                                <EditIcon />
                              </IconButton>
                              <IconButton
                                onClick={() =>
                                  handleDeleteBroker(broker.brokerName)
                                }
                              >
                                <DeleteIcon />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                  <Pagination
                    count={Math.ceil(visibleBrokers.length / itemsPerPage)}
                    page={currentPage}
                    onChange={(_, page) => setCurrentPage(page)}
                    sx={{
                      py: 2,
                      display: "flex",
                      justifyContent: "center",
                    }}
                  />
                </TableContainer>
              )}
              <Dialog
                open={isAddBrokerModalOpen}
                onClose={closeAddBrokerModal}
                fullWidth
              >
                <DialogTitle>Add New Broker</DialogTitle>
                <DialogContent>
                  {isLoading ? (
                    <Box
                      sx={{
                        m: 2,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        textAlign: "center",
                      }}
                    >
                      <Spinner sx={{ color: "#3c94fc" }} />
                    </Box>
                  ) : (
                    <>
                      <label htmlFor="brokerName" className="input-label">
                        Broker Name :
                      </label>
                      <TextField
                        fullWidth
                        variant="standard"
                        InputLabelProps={{
                          shrink: true,
                          style: { color: "#fff" },
                        }}
                        inputProps={{ style: { color: "#fff" } }}
                        value={newBroker.brokerName}
                        onChange={(e) =>
                          setNewBroker({
                            ...newBroker,
                            brokerName: e.target.value,
                          })
                        }
                        margin="dense"
                      />
                      <label htmlFor="papsEmail" className="input-label">
                        PAPS Email :
                      </label>
                      <TextField
                        fullWidth
                        variant="standard"
                        InputLabelProps={{
                          shrink: true,
                          style: { color: "#fff" },
                        }}
                        inputProps={{ style: { color: "#fff" } }}
                        value={newBroker.papsEmail}
                        onChange={(e) =>
                          setNewBroker({
                            ...newBroker,
                            papsEmail: e.target.value,
                          })
                        }
                        margin="dense"
                      />
                      <label htmlFor="parsEmail" className="input-label">
                        PARS Email :
                      </label>
                      <TextField
                        fullWidth
                        variant="standard"
                        InputLabelProps={{
                          shrink: true,
                          style: { color: "#fff" },
                        }}
                        inputProps={{ style: { color: "#fff" } }}
                        value={newBroker.parsEmail}
                        onChange={(e) =>
                          setNewBroker({
                            ...newBroker,
                            parsEmail: e.target.value,
                          })
                        }
                        margin="dense"
                      />
                      <label htmlFor="brokerPhone" className="input-label">
                        Phone Number :
                      </label>
                      <TextField
                        fullWidth
                        variant="standard"
                        InputLabelProps={{
                          shrink: true,
                          style: { color: "#fff" },
                        }}
                        inputProps={{ style: { color: "#fff" } }}
                        value={newBroker.brokerPhone}
                        onChange={(e) =>
                          setNewBroker({
                            ...newBroker,
                            brokerPhone: e.target.value,
                          })
                        }
                        margin="dense"
                      />
                    </>
                  )}
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleAddBroker} color="primary">
                    Submit
                  </Button>
                  <IconButton
                    onClick={closeAddBrokerModal}
                    aria-label="close"
                    style={{
                      position: "absolute",
                      top: "10px",
                      right: "10px",
                      zIndex: 9000,
                      border: "2px solid red",
                    }}
                  >
                    <CloseIcon style={{ color: "red" }} />
                  </IconButton>
                </DialogActions>
              </Dialog>
            </Card>
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
}

export default BrokersList;
